import { Row } from "antd";
import MeetingMedia from "../meeting/Media";
import ShareLayout from "./ShareLayout";
import ShareMeetingDetailHeader from "./ShareMeetingDetailHeader";
import ShareDetailSections from "./ShareDetailSections";
import { useEffect, useState } from "react";
import Api from "../../app/api";
import DemoModal from "./DemoModal";
import Loading from "../loading/Index";
import InvalidShare from "./InvalidShare";
import { useParams } from "react-router-dom";

function Share() {
  const [meeting, setMeeting] = useState();
  const [demoOpen, setDemoOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [validMeeting, setValidMeeting] = useState(true);
  const { token } = useParams("id");

  useEffect(() => {
    async function fetch() {
      const meeting = await Api("getSharedMeeting", { token });

      if (meeting && meeting.status && meeting.status === "INVALID") {
        setValidMeeting(false);
      }

      setMeeting({ ...meeting, seen: true });
      setLoading(false);
    }

    fetch();
  }, []);

  const handleOpenDemo = (e) => {
    setDemoOpen(true);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <ShareLayout openDemoModal={() => handleOpenDemo()}>
      <DemoModal open={demoOpen} close={() => setDemoOpen(false)} />
      <ShareMeetingDetailHeader meeting={meeting} />

      {validMeeting ? (
        <>
          <Row className="meeting-drawer-wrapper">
            <ShareDetailSections />
            <MeetingMedia meeting={meeting} span={21} />
          </Row>
        </>
      ) : (
        <>
          <InvalidShare />
          <ShareDetailSections />
        </>
      )}
    </ShareLayout>
  );
}

export default Share;
