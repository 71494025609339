import { Layout, Button, Form, Input } from "antd";
import { logout, setPassword } from "./slice";
import styles from "./LoggedIn.module.css";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import __ from "../../app/i18n";
import { useEffect } from "react";

const { Header } = Layout;

export default function SetPassword() {
  return (
    <Layout>
      <Header className="header">
        <div className="logo" />
      </Header>
      <div className={styles.loginFormContainer}>
        <App />
      </div>
    </Layout>
  );
}

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { passwordToken } = useParams();

  useEffect(() => {
    dispatch(logout());
  }, []);

  const onFinish = (params) => {
    dispatch(setPassword({ password: params.password, createPasswordToken: decodeURIComponent(passwordToken) }));
    
    return navigate('/meetings');
  };

  return (
    <Form
      className={styles.loginForm}
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 8,
      }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        label={__('generic.fields.password')}
        name="password"
        rules={[
          {
            required: true,
            message: "Password is required",
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 8,
        }}
      >
        <Button type="primary" htmlType="submit">
          { __('generic.fields.setPassword') }
        </Button>
      </Form.Item>
    </Form>
  );
};
