import { useDispatch, useSelector } from "react-redux";
import { Row, Comment, Typography, Tooltip } from "antd";
import { moveVideo, selectMeeting, selectParticipants } from "../../slice";
import __ from "../../../../app/i18n";
import styles from "./Metrics.module.css";
import "./Metrics.module.css";
import CommentStyles from "./../comments/Comment.module.css";
import { Bar } from "@ant-design/plots";
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import { getTimestampOfMessageToPlay, removeAccents, secondsToHHMMSS } from "../../../../app/utils";
import { useState } from "react";
const { Panel } = Collapse;

const TrackerMeeting = () => {
  const dispatch = useDispatch();
  const panelStyle = {
    marginBottom: 24,
    background: "#ddd",
    border: "none",
  };

  const participants = useSelector(selectParticipants);
  const meeting = useSelector(selectMeeting);
  const trackers = meeting.metrics?.trackers;
  const trackerWords = (meeting.metrics?.trackerWords || []).map(i => removeAccents(i));
  const [trackerVisibles, setTrackerVisibles] = useState([]);

  if (!trackers || !trackers.length) {
    return;
  }

  let data = (trackers || []).reduce((prev, curr) => {
    const stakeHolderCount = curr.messages.filter(
      (m) => m.speakerType === "STAKEHOLDER"
    ).length;
    const userCount = curr.messages.filter(
      (m) => m.speakerType === "USER"
    ).length;

    prev.push({
      label: curr.tracker,
      type: __("generic.fields.seller"),
      value: userCount,
    });
    prev.push({
      label: curr.tracker,
      type: __("generic.fields.client"),
      value: stakeHolderCount,
    });

    return prev;
  }, []);

  const playLine = (item) => {
    const timestamp = getTimestampOfMessageToPlay(meeting, item);
  
    dispatch(moveVideo(timestamp));
    document.querySelector('video').play();
  };

  
  const config = {
    data: data.reverse(),
    isStack: true,
    xField: "value",
    yField: "label",
    seriesField: "type",
    width: 1000,
    label: {
      position: "middle",
      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
        {
          type: "adjust-color",
        },
      ],
    },
  };

  const renderName = (user) => {
    const color = participants.find(p => p.name === user.name).color;
    return (
      <>
        {initials({ name: user.name, color })}
      </>
    );
  };
  
  const initials = ({name, color}) => {
    const initials = name
      .split(" ")
      .map((w) => w.charAt(0))
      .join("");
  
    return <span className={CommentStyles.initialsBuble} style={{ backgroundColor: color }}>{initials}</span>;
  };

  const renderMessageText = (text) => {
    return text.split(' ').map(transcriptionMessage => {
      const message = removeAccents(transcriptionMessage).toUpperCase();

      const matches = trackerWords.map(w => removeAccents(w).toUpperCase()).filter(w => message.includes(w));
      if (matches.length) {
        return <><b style={{ background: "yellow" }}>{transcriptionMessage}</b> {' '}</>
      } else {
        return <><span>{transcriptionMessage + ' '}</span></>
      }

    });
  };

  const renderMessage = (message, trackerKey) => {
    const who = message.speaker;
    const text = message.message;

    let time;
    if (meeting.provider === 'GOOGLE') {
      time = secondsToHHMMSS(getTimestampOfMessageToPlay(meeting, message));
    } else {
      time = secondsToHHMMSS(message.startAt);
    }

    return <Comment
      key={`trackerMessage${message.id}`}
      style={{ cursor: "pointer !important" }}
      onClick={() => playLine(message)}
      author={<a>{who}</a>}
      avatar={ renderName({ name: who })
      }
      datetime={
        <Tooltip title={time}>
          <span>{time}</span>
        </Tooltip>
      }
      
      content={<p style={{ cursor: "pointer" }}>{renderMessageText(text)}</p>}
    />;
  };

  const renderDetails = () => {
    return (
      <Collapse
        bordered={false}
        defaultActiveKey={[]}
        onChange={(visibleTrackers) => setTrackerVisibles(visibleTrackers)}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        style={{
          marginTop: 35,
        }}
      >
        {(trackers || []).map((tracker, i) => (
          <Panel
            header={tracker.tracker}
            key={`tracker_#${i + 1}`}
            style={panelStyle}
            className={'tracker-panel'}
          >
            { trackerVisibles.includes(`tracker_#${i + 1}`) ? (tracker.messages || []).map(m => renderMessage(m, `tracker_#${i + 1}`)) : '' }
          </Panel>
        ))}
      </Collapse>
    );
  };

  return (
    <div className={styles.siteStatisticDemoCard} key={'trackerMeeting'}>
      <Typography.Title level={4}>
        {__("metrics.trackers.label")}
      </Typography.Title>
      <Row gutter={16}>
        <Bar {...config} />
      </Row>

      { renderDetails() }
    </div>
  );
};

export default TrackerMeeting;
