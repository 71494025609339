import { selectMessages } from "./slice";
import { useSelector } from "react-redux";
import styles from "./styles.module.css";
import { Comment } from "antd";
import moment from "moment";
import Message from "./Message";
import Audio from "./Audio";

export default function Chat() {
  const messages = useSelector(selectMessages);

  const getComponent = (comment) => comment.outputType === "audio" ? (
    <Audio comment={comment} />
  ) : (
    <Message comment={comment} />
  );

  return (
    <div>
      {messages.map(getComponent)}
    </div>
  );
}
