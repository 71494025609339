import { Drawer, Button, Form, Input, Checkbox, Select } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import __ from "../../app/i18n";
import { updateUser } from "./slice";
import { appMeetingLangs } from "../../app/utils";

export default function EditTeamUser({ open, onClose, user }) {
  const dispatch = useDispatch();

  if (!open || !user) return

  const handleNewUser = (values) => {
    dispatch(updateUser({ ...values, userId: (user.id || user._id) }));
    onClose()
  };

  const roles = [
    __("roles.collaborator"),
    __("roles.seller"),
    __("roles.manager"),
  ];
  
  const langOptions = appMeetingLangs.map(lang => <Select.Option key={lang.value}>{lang.label}</Select.Option>);
  const options = [];
  roles.forEach((v, i) => {
    options.push(<Select.Option key={String(i)}>{v}</Select.Option>);
  });

  return (
    <Drawer
      title={__("team.list.editUser")}
      placement={"right"}
      width={500}
      onClose={() => onClose()}
      open={open}
      getContainer={true}
    >
      <Form
        name="basic"
        layout="vertical"
        onFinish={handleNewUser}
        autoComplete="off"
      >
        <Form.Item
          name="name"
          label={__("generic.fields.name")}
          initialValue={user.uName}
          rules={[
            {
              required: true,
              message: __("generic.required.name"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="lastName"
          initialValue={user.uLastName}
          label={__("generic.fields.lastName")}
          rules={[
            {
              required: true,
              message: __("generic.required.lastName"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="E-mail"
          name="email"
          initialValue={user.email}
          rules={[
            {
              required: true,
              message: __("generic.required.email"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label={__("team.list.role")} name="role">
          <Select defaultValue={String(user.role)}>{options}</Select>
        </Form.Item>

        <Form.Item label={__("generic.fields.lang")} name="lang">
          <Select defaultValue={String(user.lang)}>{langOptions}</Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {__("generic.fields.updateUser")}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
