import { Card, Layout, Pagination, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadLibraries,
  selectLibraries,
  selectIsFetching,
  selectFetchedLibraries,
  deleteLibrary,
} from "./slice";
import Meta from "antd/lib/card/Meta";
import { FolderAddOutlined, DeleteOutlined } from "@ant-design/icons";
import __ from "../../app/i18n";
import styles from "./Library.module.css";
import NewLibraryModal from "./NewLibraryModal";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Loading from "../loading/Index";
import ManagerComponent from "../session/ManagerComponent";

const Index = () => {
  const [isNewModalOpen, setOpenNewLibrary] = useState(false);
  const libraries = useSelector(selectLibraries);
  const dispatch = useDispatch();
  const isFetching = useSelector(selectIsFetching);
  const fetched = useSelector(selectFetchedLibraries);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(loadLibraries());
  }, [dispatch, fetched]);

  if (isFetching) {
    return <Loading />;
  }

  const handleDeleteLibrary = (library) => {
    dispatch(deleteLibrary({ libraryId: library.id }));
  };

  return (
    <Content style={{ padding: "0 25px" }}>
      <Layout
        className={"site-layout-background main-layout-container"}
        style={{ padding: "24px 0" }}
      >
        <NewLibraryModal
          onClose={() => setOpenNewLibrary(false)}
          open={isNewModalOpen}
        />
        <Content style={{ padding: "0 24px", minHeight: 280 }}>
          <Row>
            {!isFetching ? (
              <Card
                className={styles.newLibraryCard}
                onClick={() => setOpenNewLibrary(true)}
              >
                +
              </Card>
            ) : (
              ""
            )}
            {!isFetching
              ? (libraries || []).map((library) => (
                  <Card className={styles.libraryItem}>
                    <Meta
                      avatar={<FolderAddOutlined />}
                      title={
                        <>
                          <div
                            onClick={() => navigate("/library/" + library.id)}
                          >
                            {library.name}
                          </div>
                          <ManagerComponent>
                            <DeleteOutlined
                              style={{
                                width: "35px",
                                position: "absolute",
                                right: 0,
                                top: "10px",
                              }}
                              onClick={(e) => handleDeleteLibrary(library)}
                            />
                          </ManagerComponent>
                        </>
                      }
                      description={
                        <div onClick={() => navigate("/library/" + library.id)}>
                          <p style={{ margin: 0, padding: 0 }}>
                            {__("library.createdBy") + ": "}
                            <b>{library.createdBy}</b>
                          </p>
                          { library.count ? <p style={{ margin: 0, padding: 0 }}>
                            <b>{library.count}</b> {__("meetings.label")}
                          </p> : <></> }
                          
                          {library.lastAdded ? (
                            <p style={{ margin: 0, padding: 0 }}>
                              {__("library.lastAdded")}{" "}
                              <b>
                                {moment(library.lastAdded).format(
                                  "DD/MM/YYYY HH:mm"
                                )}
                              </b>
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      }
                    />
                  </Card>
                ))
              : ""}
          </Row>
          <br />
          <Pagination
            hideOnSinglePage
            onChange={() => {}}
            defaultCurrent={1}
            pageSize={20}
            total={libraries.length}
          />
        </Content>
      </Layout>
    </Content>
  );
};

export default Index;
