import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { selectUser } from "../session/slice";

const Ping = () => {
  const user = useSelector(selectUser);
  const location = useLocation();

  useEffect(() => {
    if (user && user._id) {
      fetch("https://api.ms.samu.ai/admin/ping", {
        method: "POST",
        body: JSON.stringify({ user: user._id, url: location.pathname }),
      });
    } 
  }, [location]);
};

export default Ping;
