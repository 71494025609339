import { createSlice } from '@reduxjs/toolkit';
import __ from '../../app/i18n';

const initialState = {
  status: 'iddle',
  messages: []
};

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.status = 'loading';
    },
    stopLoading: (state) => {
      state.status = 'iddle';
    },
    showGlobalSuccess: (state, action) => {
      state.success = action.payload;
    },
    showGlobalErrors: (state, action) => {
      const errors = (action.payload || []).map(e => __(`generic.errors.${e.reason}`.toLowerCase()));
      
      state.errors = errors;
    },
    showGlobalMessage: (state, action) => {
      state.messages = action.payload;
    },
    cleanGlobalMessages: (state) => {
      state.messages = [];
    }
  },
});

export const { startLoading, stopLoading, showGlobalMessage, showGlobalSuccess, showGlobalErrors, cleanGlobalMessages } = loadingSlice.actions;

export const selectIsLoading = (state) => state.loading.status === 'loading';
export const selectGlobalMessages = (state) => state.loading.messages;
export const selectGlobalSuccess = (state) => state.loading.success;
export const selectGlobalErrors = (state) => state.loading.errors;

export default loadingSlice.reducer;
