import { Badge, Typography } from "antd";
import { Modal } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../session/slice";
import { Collapse } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
const { Panel } = Collapse;

export const SamuScoreModal = ({ meeting, open, onClose }) => {
  const user = useSelector(selectUser);
  const samuScore = meeting.samuScore || meeting.customScore;

  const clean = (i) =>
    i.replace(
      "(lo más importante es entender si el prospecto cuenta sus puntos de dolor y el impacto de esos puntos de dolor en el negocio)",
      ""
    );

  let title = "Samu Score";
  if (meeting.customScore) {
    title = (user.accountName || "Samu") + " score";

    return (
      <CustomSamuScore
        clean={clean}
        title={title}
        open={open}
        onClose={onClose}
        samuScore={samuScore}
      />
    );
  }

  return (
    <Modal
      title={title}
      width={1000}
      open={open}
      cancelButtonProps={{ hidden: true }}
      onOk={onClose}
      onCancel={onClose}
    >
      {(samuScore.items || []).map((it, i) => {
        const { item, reason } = it;
        return (
          <React.Fragment key={i}>
            <Typography.Title level={5}>{clean(item)}</Typography.Title>
            <Typography.Text>{reason}</Typography.Text>
          </React.Fragment>
        );
      })}
    </Modal>
  );
};

const CustomSamuScore = ({ title, open, onClose, samuScore, clean }) => {
  return (
    <Modal
      title={title}
      width={1000}
      open={open}
      cancelButtonProps={{ hidden: true }}
      onOk={onClose}
      onCancel={onClose}
    >
      <Collapse defaultActiveKey={["1"]} onChange={() => {}}>
        {(samuScore.items || []).map((it, i) => {
          const subItemsPassed = it.subItems.filter((i) => i.detected).length;
          const subItemsTotal = it.subItems.length;

          let color = "red";
          if (subItemsPassed === subItemsTotal) {
            color = "green";
          } else if (subItemsPassed > 0) {
            color = "yellow";
          }

          return (
            <Panel
              header={
                <>
                  <Badge color={color} /> [{subItemsPassed}/{subItemsTotal}] {it.item}
                </>
              }
              key={i}
            >
              <ul style={{ listStyle: "none", padding: 0 }}>
                {it.subItems.map((evaluable) => {
                  const icon = evaluable.detected ? (
                    <CheckCircleOutlined
                      style={{
                        fontSize: "25px",
                        color: "green",
                        verticalAlign: "middle",
                        display: "inline-block",
                      }}
                    />
                  ) : (
                    <CloseCircleOutlined
                      style={{
                        fontSize: "25px",
                        color: "red",
                        verticalAlign: "middle",
                        display: "inline-block",
                      }}
                    />
                  );

                  return (
                    <li style={{ display: "block", marginBottom: "15px" }}>
                      {icon} <b style={{ marginLeft: 5 }}>{evaluable.prompt}</b>
                      {evaluable.reason ? (
                        <i style={{ display: "block" }}>{evaluable.reason}</i>
                      ) : (
                        ""
                      )}
                    </li>
                  );
                })}
              </ul>
            </Panel>
          );
        })}
      </Collapse>
      <Typography.Title level={4} style={{ marginTop: "25px" }}>
        Puntaje obtenido: {Math.round((samuScore.total) * 10) / 10}
      </Typography.Title>
    </Modal>
  );
};
