import { useDispatch, useSelector } from "react-redux";
import { Badge, Drawer, Typography } from "antd";
import { closeSection, selectMeeting, selectOpenSection } from "../../slice";
import __ from "../../../../app/i18n";
import { useEffect, useState } from "react";
import { getRandomColors } from "../../../../app/color";
import Section from "../Section";

const Deal = () => {
  const meeting = useSelector(selectMeeting);
  const [colors, setColors] = useState([]);

  useEffect(() => {
    if (
      meeting &&
      Array.isArray(meeting.users) &&
      Array.isArray(meeting.stakeholders)
    ) {
      const qty = Math.max(meeting.users.length, meeting.stakeholders.length);
      const c = getRandomColors(qty);

      setColors(c);
    }
  }, [meeting.stakeholders, meeting.users]);

  return (
    <Section sectionKey="deal" title={__("meetings.details.sections.deal")}>
      {meeting.client ? (
        <>
          <Typography.Title level={4}>
            {__("meetings.filters.client")}
          </Typography.Title>
          <Typography.Text level={4}>{meeting.client}</Typography.Text>
        </>
      ) : (
        ""
      )}

      {meeting.providerLink ? (
        <>
          <Typography.Title level={4}>{__("deal.link")}</Typography.Title>
          <Typography.Text level={4}>
            <a href={meeting.providerLink}>{meeting.providerLink}</a>
          </Typography.Text>
        </>
      ) : (
        ""
      )}
      <Typography.Title level={4}>{__("deal.stakeholders")}</Typography.Title>
      <Typography.Text level={4}>
        {(meeting.stakeholders || []).map((stakeholderEmail, index) => (
          <>
            <Badge
              key={`bagde_${index}_index_guests`}
              color={colors[index]}
              text={
                <Typography.Text type="secondary">
                  {stakeholderEmail}
                </Typography.Text>
              }
            />
            <br />
          </>
        ))}

        {(meeting.stakeholders || []).length === 0 ? __("deal.empty") : ""}
      </Typography.Text>

      <Typography.Title level={4}>{__("deal.users")}</Typography.Title>
      <Typography.Text level={4}>
        {(meeting.users || []).map((u, index) => (
          <>
            <Badge
              key={`bagde_${index}_index_guests`}
              color={colors[index]}
              text={
                <Typography.Text type="secondary">
                  {u.name} {u.lastName}
                </Typography.Text>
              }
            />
            <br />
          </>
        ))}

        {(meeting.users || []).length === 0 ? __("deal.empty") : ""}
      </Typography.Text>
    </Section>
  );
};

export default Deal;
