import { Button, Select } from "antd";
import { useSelector } from "react-redux";
import * as primarySlice from "./slice";
import * as secondarySlice from "./Compare/slice";
import { Download } from "tabler-icons-react";
import __ from "../../app/i18n";
import { selectIsMonthlyReportEnabled } from "../session/slice";
import Api from "../../app/api";
import { useEffect, useState } from "react";
import moment from "moment";
import { firstUp } from "../meetings/Item";

const getMonthNameByValue = (index) =>
  firstUp(moment({ month: index }).format("MMMM").replace("º", ""));

const DownloadMonthlyReportButton = (
  { isSecondary } = { isSecondary: false }
) => {
  const { selectAnalyticsState } = isSecondary ? secondarySlice : primarySlice;
  const analyticsState = useSelector(selectAnalyticsState);
  const isMonthlyReportEnabled = useSelector(selectIsMonthlyReportEnabled);
  const [months, setMonths] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);

  useEffect(() => {
    const render = () => {
      const monthFrom = 5;
      const monthTo = moment().month() + 1;
      const map = new Map();

      for (let index = monthFrom; index < monthTo; index++) {
        const label = getMonthNameByValue(index);
        map.set(index, { key: index, value: index, label });
      }

      setMonths(Array.from(map.values()));
    };

    return render();
  }, []);

  if (!analyticsState) {
    return <h2>Loading</h2>;
  }

  if (isMonthlyReportEnabled) {
    return (
      <>
        <Select
          style={{ width: "150px" }}
          value={getMonthNameByValue(currentMonth - 1)}
          options={months}
          onChange={(v) => setCurrentMonth(v + 1)}
        ></Select>
        <Button
          icon={<Download size={12} />}
          style={{ fontSize: "12px" }}
          onClick={async () => {
            const response = await Api("getMonthlyReport", {
              date: moment({ month: currentMonth - 1 }).format("YYYY-MM"),
            });
            if (response && response.reportUrl) {
              document.location.href = response.reportUrl;
            }
          }}
        >
          <span style={{ marginLeft: "5px" }}>
            {__("reports.downloadMonthlyReport")}
          </span>
        </Button>
      </>
    );
  }
};

export default DownloadMonthlyReportButton;
