import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import __ from "../../app/i18n";
import { clearFilters, selectHasFiltersApplied } from "./filters/slice";
import { CloseCircleOutlined } from '@ant-design/icons'

export default function ClearFilters() {
  const hasFiltersApplied = useSelector(selectHasFiltersApplied);
  const dispatch = useDispatch();

  const handleClear = () => {
    document.location.reload();
  };

  if (hasFiltersApplied) {
    return (
      <Button
      icon={<CloseCircleOutlined />}
        style={{
          marginBottom: "10px",
          borderRadius: "50px",
          textAlign: "right",
          color: "#1791ff"
        }}
        onClick={handleClear}
      >
      {__("meetings.filters.clear")}
      </Button>
    );
  }
}
