import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../../app/api';
import __ from '../../app/i18n';
import {
  showGlobalErrors,
  showGlobalSuccess,
  startLoading,
  stopLoading,
} from "../loading/slice";

const initialState = {
  notifications: [],
  lastDateChecked: null
};

export const loadNotifications = createAsyncThunk(
  'general/loadNotifications',
  async (userId, { getState }) => {
    const response = await API('loadNotifications', { userId })

    return response;
  }
);

export const notificationSeen = createAsyncThunk(
  'general/notificationSeen',
  async () => {
    const response = await API('notificationSeen', {})

    return response;
  }
);

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
  },

  extraReducers: (builder) => {
    builder
      .addCase(loadNotifications.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loadNotifications.fulfilled, (state, action) => {
        state.status = 'idle';
        state.notifications = action.payload.notifications;
        state.lastDateChecked = action.payload.lastDateChecked;
      })
      .addCase(notificationSeen.fulfilled, (state) => {
        state.lastDateChecked = new Date();
      });
  },
});

// export const { openSection, closeSection, toggleCC, moveVideo, setVideoDuration, setCurrentDisplayingMessage } = notificationsSlice.actions;

export const selectNotifications = (state) => state.notifications.notifications;
export const selectCountUnreadNotifications = (state) => {
  if (!state.notifications.lastDateChecked) {
    return state.notifications.notifications;
  }

  return (state.notifications.notifications || []).filter(n => n.createdAt > state.notifications.lastDateChecked);
};

export default notificationsSlice.reducer;
