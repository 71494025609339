import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../../app/api';

const initialState = {
  status: 'iddle',
  conversation: {},
};

export const loadConversation = createAsyncThunk(
  'conversation/fetchConversation',
  async (id) => {
    const response = await API('getConversationDetails', { id });

    return response;
  }
);


export const conversationSlice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(loadConversation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loadConversation.fulfilled, (state, action) => {
        state.status = 'idle';
        state.conversation = action.payload;
      })
  },
});

export const selectConversation = (state) => state.conversation?.conversation;
export const selectIsLoading = (state) => state.conversation.status === 'loading';
export const selectMessages = (state) => state.conversation.conversation?.messages || [];

export default conversationSlice.reducer;
