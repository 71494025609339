import { Layout, Button, Form, Input } from "antd";
import { login } from "./slice";
import styles from "./LoggedIn.module.css";
import { useDispatch } from "react-redux";
import __ from "../../app/i18n";
import logo from '../../brand/Isologotype/samu_light_bckgrnd.png'
const { Header } = Layout;

export default function LoginForm(props) {

  return (
    <Layout>
      <Header className="header">
        <div className="logo" />
      </Header>
      <div className={styles.loginFormContainer}>
        <App />
      </div>
    </Layout>
  );
}

const App = () => {
  const dispatch = useDispatch();
  const onFinish = (params) => {
    dispatch(login(params));

    return false;
  };

  return (
    <Form
      className={styles.loginForm}
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 8,
      }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <div className={styles.iconContainer}>
        <img src={logo} width={350}/>
      </div>
      <Form.Item
        label="E-mail"
        name="email"
        rules={[
          {
            required: true,
            message: "E-mail is required",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={__('generic.fields.password')}
        name="password"
        rules={[
          {
            required: true,
            message: "Password is required",
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 8,
        }}
      >
        <Button type="primary" htmlType="submit">
          {__('generic.fields.login')}
        </Button>
      </Form.Item>
    </Form>
  );
};
