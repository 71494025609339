import { Space, Alert } from 'antd';
import __ from '../../../app/i18n';

const CanNotRecord = () => {
return <Space
    direction="vertical"
    style={{
      marginBottom: 15,
      display: 'block',
      maxWidth: '700px'
    }}
  >
    <Alert message={__('generic.alerts.cantRecord')} banner />
    
    </Space>
};
export default CanNotRecord;
