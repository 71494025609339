import { Badge, message, Modal, Typography } from "antd";
import {
  CalendarTwoTone,
  TeamOutlined,
  TrophyOutlined,
  AimOutlined,
} from "@ant-design/icons";

import Api from "../../app/api";
import __ from "../../app/i18n";
import styles from "./agenda.module.css";
import moment from "moment";

const badgeColors = [
  "pink",
  "red",
  "yellow",
  "orange",
  "cyan",
  "green",
  "blue",
  "purple",
  "geekblue",
  "magenta",
  "volcano",
  "gold",
  "lime",
  "pink",
  "red",
  "yellow",
  "orange",
  "cyan",
  "green",
  "blue",
  "purple",
  "geekblue",
  "magenta",
  "volcano",
  "gold",
  "lime",
  "pink",
  "red",
  "yellow",
  "orange",
  "cyan",
  "green",
  "blue",
  "purple",
  "geekblue",
  "magenta",
  "volcano",
  "gold",
  "lime",
  "pink",
  "red",
  "yellow",
  "orange",
  "cyan",
  "green",
  "blue",
  "purple",
  "geekblue",
  "magenta",
  "volcano",
  "gold",
  "lime",
];

const useAgendaMeetingDetails = (onDelete = () => {}) => {
  const seeMeetingDetails = (meeting) => {
    const hide = message.loading(__("generic.fields.loading"), 0);

    const handleCancel = async () => {
      const removeLoading = message.loading(__("generic.fields.loading"), 0);

      Api("cancelMeeting", { id: meeting._id }).then(() => {
        removeLoading();

        message.success(__("generic.alerts.meetingCanceled"), 10);
        onDelete();
      });
    };

    Api("getMeetingDetails", { id: meeting._id }).then((data) => {
      hide();

      Modal.confirm({
        icon: null,
        className: styles.agendaModalContainer,
        content: (
          <>
            <ul className={styles.agendaContentModalList}>
              <li
                className={styles.agendaContentModalLine}
                key={`${data._id}_title_item`}
              >
                <CalendarTwoTone className={styles.agendaContentModalIcon} />
                <Typography.Title
                  className={styles.agendaContentModalTitle}
                  style={{ marginBottom: 0 }}
                  level={2}
                >
                  {data.name}
                </Typography.Title>

                <Typography.Text type="secondary" style={{ display: "block" }}>
                  {moment(data.dateFrom).format("dddd, Do MMMM")} ⋅{" "}
                  {moment(data.dateFrom).format("hh:mm")} -{" "}
                  {moment(data.dateTo).format("hh:mm")}
                </Typography.Text>
              </li>
              {data.client ? (
                <li
                  className={styles.agendaContentModalLine}
                  style={{ marginTop: "20px" }}
                  key={`${data._id}_title_item2`}
                >
                  <AimOutlined
                    className={styles.agendaContentModalIcon}
                    style={{ paddingBottom: "5px" }}
                  />
                  <Typography.Text type="secondary">
                    {data.client}
                  </Typography.Text>
                </li>
              ) : (
                ""
              )}

              <li
                className={styles.agendaContentModalLine}
                key={`${data._id}_title_item3`}
              >
                <TrophyOutlined
                  className={styles.agendaContentModalIcon}
                  style={{ paddingBottom: "5px" }}
                />
                <Typography.Text type="secondary">{data.host} </Typography.Text>
              </li>

              {data.users.length ? (
                <li
                  className={styles.agendaContentModalLine}
                  key={`${data._id}_title_item4`}
                >
                  <TeamOutlined
                    className={styles.agendaContentModalIcon}
                    style={{ paddingBottom: "5px", verticalAlign: "top" }}
                  />
                  <div style={{ display: "inline-block" }}>
                    {data.users.map((u, index) => (
                      <>
                        <Badge
                          key={`bagde_${index}_index_guests`}
                          color={badgeColors[index]}
                          text={
                            <Typography.Text type="secondary">
                              {u.email}
                            </Typography.Text>
                          }
                        />
                        <br />
                      </>
                    ))}

                    {data.stakeholders.map((u, index) => (
                      <>
                        <Badge
                          key={`bagde_${index}_index`}
                          color={badgeColors[index]}
                          text={
                            <Typography.Text type="secondary">
                              {u}
                            </Typography.Text>
                          }
                        />
                        <br />
                      </>
                    ))}
                  </div>
                </li>
              ) : (
                ""
              )}
            </ul>
          </>
        ),
        onOk() {},
        okButtonProps: { className: styles.btnOk },
        cancelButtonProps: { className: styles.btnCancelMeeting },
      });
    });
  };

  return { seeMeetingDetails };
};

export default useAgendaMeetingDetails;
