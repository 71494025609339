import { Divider, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import __ from "../../app/i18n";
import {
  getConfig,
  selectConfigFetched,
  selectIsMicrosoftTeamsEnabled,
  selectUser,
} from "../session/slice";
import GoogleTab from "./tabs/GoogleTab";
import MicrosoftTab from "./tabs/MicrosoftTab";
import { updateUser } from "../team/slice";
import { appMeetingLangs } from "../../app/utils";

const Profile = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  document.title = `${__("appName")} - ${__("menu.profile")}`;
  const [lang, setLang] = useState(user.lang);
  const configFetched = useSelector(selectConfigFetched);
  const isMicrosoftTeamsEnabled = useSelector(selectIsMicrosoftTeamsEnabled);

  const handleLangUpdate = (l) => {
    setLang(l);
    dispatch(updateUser({ lang: l, userId: user.id || user._id }));
  };

  useEffect(() => {
    if (!configFetched) {
      dispatch(getConfig());
    }
  }, [configFetched]);

  const langOptions = appMeetingLangs.map((lang) => ({
    value: lang.value,
    label: lang.label,
  }));

  return (
    <div style={{ minHeight: "87vh", margin: "15px 30px" }}>
      <Typography.Title>{__("menu.profile")}</Typography.Title>
      <div style={{ background: "#fff" }}>
        <div>
          <ul style={{ listStyle: "none", margin: 0, padding: "30px" }}>
            <li>
              <Typography.Title level={5}>
                {__("generic.fields.name")}
              </Typography.Title>
              <Typography.Text>{user.name}</Typography.Text>
            </li>
            <Divider />
            <li>
              <Typography.Title level={5}>
                {__("generic.fields.lastName")}
              </Typography.Title>
              <Typography.Text>{user.lastName}</Typography.Text>
            </li>
            <Divider />
            <li>
              <Typography.Title level={5}>
                {__("generic.fields.email")}
              </Typography.Title>
              <Typography.Text>{user.email}</Typography.Text>
            </li>
            <Divider />
            <li>
              <Typography.Title level={5}>
                {__("generic.fields.lang")}
              </Typography.Title>
              <Typography.Text
                style={{
                  display: "block",
                  marginTop: "-10px",
                  marginBottom: "15px",
                }}
                type="secondary"
              >
                {__("generic.fields.langUsage")}
              </Typography.Text>

              <Select
                options={langOptions}
                value={__(`lang.${String(lang).toLowerCase()}`)}
                onChange={(e) => handleLangUpdate(e)}
                style={{ minWidth: "100px" }}
              />
            </li>
            <Divider />
            <li>
              <Typography.Title level={5}>Google Calendar</Typography.Title>
              <GoogleTab />
            </li>
            {isMicrosoftTeamsEnabled ? (
              <>
                <Divider />
                <li>
                  <Typography.Title level={5}>
                    Microsoft Outlook
                  </Typography.Title>
                  <MicrosoftTab />
                </li>
              </>
            ) : (
              ""
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Profile;
