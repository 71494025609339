import { useSelector } from "react-redux";
import { Col, Row, Statistic, Card, Typography } from "antd";
import {
  selectMeeting,
} from "../../slice";
import __ from "../../../../app/i18n";
import styles from "./Metrics.module.css";
import { Bar } from '@ant-design/plots';

const SpokenTimesMetric = () => {
  const meeting = useSelector(selectMeeting);
  const { meetingSpokenTimeByParticipant } = meeting.metrics;

  const data = Object.keys(meetingSpokenTimeByParticipant || []).reduce((prev, curr) => {
    const item = {
      name: meeting.metrics.participants[curr],
      value: Math.ceil(meeting.metrics.meetingSpokenTimeByParticipant[curr] / 60)
    };
    prev.push(item)

    return prev;
  }, []);

  const config = {
    data,
    xField: 'value',
    yField: 'name',
    seriesField: 'name',
    legend: {
      position: 'top-left',
    },
  };

  return (
    <div className={styles.siteStatisticDemoCard}>
      <Typography.Title level={4}>
        {__("metrics.busySpeakers.label")}
      </Typography.Title>
      <div className={styles.siteStatisticDemoCard}>
        <div style={{ padding: '15px' }}>
          <Bar {...config} />
        </div>
      </div>
    </div>
  );
};

export default SpokenTimesMetric;
