import { useSelector } from "react-redux";
import { Col, Row, Statistic, Card, Typography, Button, Space } from "antd";
import { selectMeeting, selectParticipants } from "../../slice";
import __ from "../../../../app/i18n";
import styles from "./Metrics.module.css";
import TranscriptionList from "../search/TranscriptionList";
import { useState } from "react";

const QuestionList = () => {
  const participants = useSelector(selectParticipants);
  const meeting = useSelector(selectMeeting);
  const [visible, setVisible] = useState(false);

  const questions = (meeting.transcription || []).filter((m) =>
    m.text.includes("?")
  );
  const questionsAmount = questions.reduce((prev, curr) => {
    if (!prev.has(curr.who)) {
      prev.set(curr.who, 0);
    }

    prev.set(curr.who, prev.get(curr.who) + 1);

    return prev;
  }, new Map());

  return (
    <div className={styles.siteStatisticDemoCard}>
      <Typography.Title level={4}>
        {__("metrics.questions.label")}
      </Typography.Title>
      <Row gutter={16}>
        {participants.map((participant) => {
          return (
            <Col span={12} key={`questionItem${participant.name}`}>
              <Card>
                <Statistic
                  title={participant.name}
                  value={questionsAmount.get(participant.name)}
                  precision={0}
                  valueStyle={{
                    color: participant.color,
                  }}
                  suffix=""
                  style={{ textAlign: "center" }}
                />
              </Card>
            </Col>
          );
        })}

        {visible ? (
          <>
            <div className={styles.questionsContent}>
              <TranscriptionList
                style={{ width: "100%", margin: "15px" }}
                showTime={true}
                q={"?"}
                meeting={meeting}
              />
            </div>
            <Button
              style={{
                width: "100%",
                margin: "15px 5px",
                background: "#1791ff",
                color: "#fff",
              }}
              onClick={() => setVisible(!visible)}
            >
              {__("generic.buttons.hideQuestions")}
            </Button>
          </>
        ) : (
          <Button
            style={{
              width: "100%",
              margin: "15px 5px",
              background: "#1791ff",
              color: "#fff",
            }}
            onClick={() => setVisible(!visible)}
          >
            {__("generic.buttons.seeQuestions")}
          </Button>
        )}
      </Row>
    </div>
  );
};

export default QuestionList;
