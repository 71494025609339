import { Spin, Tooltip, Typography } from "antd";
import styles from "./Media.module.css";
import { selectParticipants, selectVideoDuration } from "./slice";
import { useSelector } from "react-redux";
import { getSecondsDiff } from "../../app/utils";
import { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { getRandomColors } from "../../app/color";

export default function MeetingTimeline({ meeting, onClickTimeline, style }) {
  const videoDuration = useSelector(selectVideoDuration);
  const participants = useSelector(selectParticipants);
  const meetingStartTimestamp = new Date(meeting.startAt).getTime();
  const [loading, setLoading] = useState(true);

  const antIcon = <LoadingOutlined style={{ fontSize: 70 }} spin />;
  const color = (host) => {
    let color;
    if (participants && participants.length) {
      color = participants.find((i) => i.name === host).color;
    } else {
      color = getRandomColors(1);
    }

    return color;
  };

  useEffect(() => {
    if (videoDuration) {
      setLoading(false);
    }
  }, [videoDuration]);

  const getPosition = (item) => {
    const offsetXa =
      (getSecondsDiff(new Date(item.startAt), new Date(meeting.dateFrom)) *
        100) /
      videoDuration;
    let offsetXb =
      (getSecondsDiff(new Date(item.endAt), new Date(item.startAt)) * 100) /
      videoDuration;

    if (!offsetXb) {
      offsetXb = 0.5;
    }

    return { left: offsetXa, width: offsetXb };
  };

  function renderHost(host, meeting) {
    const guestColor = color(host);

    return (meeting.transcription || [])
      .filter((item) => item.who === host)
      .map((item) => {
        const data = getPosition(item);

        return (
          <Tooltip
            title={item.text}
            onClick={() =>
              onClickTimeline(
                item,
                meetingStartTimestamp,
                new Date(item.startAt).getTime() - meetingStartTimestamp
              )
            }
          >
            <span
              className={styles.mediaTimeLineItem}
              style={{
                left: `${data.left}%`,
                width: `${data.width}%`,
                backgroundColor: guestColor,
                transition: "all 1s",
              }}
            ></span>
          </Tooltip>
        );
      });
  }

  let guests = [];
  if (meeting && meeting.transcription && Array.isArray(meeting.transcription)) {
    guests = Array.from(new Set(meeting.transcription.map((i) => i.who)));
  }

  return (
    <div>
      {loading ? <Spin className={styles.loading} indicator={antIcon} /> : ""}
      <div style={{ transition: "all 0.5s", opacity: loading ? 0 : 1 }}>
        {(guests || []).map((guest) => {
          const key =
            "guest_" + (guest || 'Desconocido').replace(/\s/g, "_") + "_" + new Date().getTime();

          return (
            <div key={key} style={style}>
              <Typography.Title level={5}>{guest}</Typography.Title>
              <div className={styles.mediaTimeLineContainer}>
                {renderHost(guest, meeting)}
                <br />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
