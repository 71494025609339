import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../app/api";
import __ from "../../app/i18n";
import {
  showGlobalErrors,
  showGlobalSuccess,
  startLoading,
  stopLoading,
} from "../loading/slice";

const initialState = {
  status: "iddle",
  meetings: [],
  myMeetings: []
};

export const createMeeting = createAsyncThunk(
  "agenda/createMeeting",
  async (payload, { dispatch }) => {
    dispatch(startLoading());

    try {
      let response;
      response = await API("createMeeting", payload);
      if (response && response.errors) {
        dispatch(showGlobalErrors(response.errors));
      } else {
        dispatch(showGlobalSuccess([__("generic.alerts.meetingCreated")]));
      }
    } catch (error) {
      dispatch(showGlobalErrors([__("generic.errors.internalServerError")]));
      dispatch(stopLoading());

      throw error;
    }

    // dispatch(loadUsers());
    dispatch(stopLoading());
  }
);

export const getTeamMeetings = createAsyncThunk(
  "agenda/getTeamMeetings",
  async (payload, { dispatch }) => {
    const response = await API("getTeamMeetings", payload);
    return response;
  }
);

export const getMyMeetings = createAsyncThunk(
  "agenda/getMyMeetings",
  async (payload, { dispatch }) => {
    const response = await API("getMyMeetings", payload);
    return response;
  }
);

export const updateMeetingSyncStatus = createAsyncThunk(
  "agenda/updateMeetingSyncStatus",
  async (payload, { dispatch }) => {
    const response = await API("updateMeetingSyncStatus", payload);
    return response;
  }
);

export const agendaSlice = createSlice({
  name: "agenda",
  initialState,
  reducers: {
    updatedMeetingPrivacy: (state, { payload }) => {
      const myMeetings = [...state.meetings];
      let obj = myMeetings.find(m => m._id === payload.id);
      if (obj) {
        obj.visibility = { type: payload.type };
      }

      const meetings = [...state.meetings ];
      obj = meetings.find(m => m._id === payload.id);
      if (obj) {
        obj.visibility = { type: payload.type };
      }

      state.myMeetings = myMeetings;
      state.meetings = meetings;
    },
    updatedMeetingLang: (state, { payload }) => {
      const myMeetings = [...state.meetings];
      let obj = myMeetings.find(m => m._id === payload.id);
      if (obj) {
        obj.lang = payload.lang;
      }

      const meetings = [...state.meetings ];
      obj = meetings.find(m => m._id === payload.id);
      if (obj) {
        obj.lang = payload.lang;
      }

      state.myMeetings = myMeetings;
      state.meetings = meetings;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getTeamMeetings.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTeamMeetings.fulfilled, (state, action) => {
        state.status = "idle";
        state.meetings = action.payload;
      })
      .addCase(getMyMeetings.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getMyMeetings.fulfilled, (state, action) => {
        state.status = "idle";
        state.meetings = action.payload;

        const myMeetings = new Map();
        action.payload.forEach(m => myMeetings.set(m._id, m));
        state.myMeetings = [...myMeetings.values()];
      })
      .addCase(updateMeetingSyncStatus.fulfilled, (state, action) => {
        const myMeetings = [...state.myMeetings ];
        let obj = myMeetings.find(m => m._id === action.meta.arg.id);
        if (obj) {
          obj.status = action.meta.arg.status;
        }

        const meetings = [...state.meetings ];
        obj = meetings.find(m => m._id === action.meta.arg.id);
        if (obj) {
          obj.status = action.meta.arg.status;
        }

        state.myMeetings = myMeetings;
        state.meetings = meetings;
      });
  },
});

export const { updatedMeetingPrivacy, updatedMeetingLang } = agendaSlice.actions;

export const selectIsFetching = (state) => state.agenda.status === "loading";
export const selectMeetings = (state) => state.agenda.meetings;
export const selectMyMeetings = (state) => state.agenda.myMeetings;

export default agendaSlice.reducer;
