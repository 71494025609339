import { Select } from 'antd';
import styles from './CallTypeSelector.module.css';
import __ from '../../../../app/i18n';
import { removeAccents } from '../../../../app/utils';
import { useDispatch, useSelector } from 'react-redux';
import { selectCallTypes } from './slice';
import { selectIsMeetingListReady, selectedCallType, updateCallTypeSelecion } from '../slice';
import { useEffect, useState } from 'react';

export default function CallTypeSelectorComponent() {
  const ready = useSelector(selectIsMeetingListReady);
  const dispatch = useDispatch();
  const callTypes = useSelector(selectCallTypes);
  const callType = useSelector(selectedCallType);
  const [value, setValue] = useState(callType ? { label: callType.name } : {});


  useEffect(() => {
    setValue(callType ? { label: callType.name } : {})
  }, [callType]);

  const options = (callTypes || []).map((item, index) => ({
    key: `callType#${index}`,
    label: item.name,
    value: item._id,
  }));

  const handleOnChange = (callTypeId) => {
    const selectedCallType = (callTypes || []).find(c => c._id === callTypeId);
    if (selectedCallType) {
      dispatch(updateCallTypeSelecion(selectedCallType));
    } else {
      dispatch(updateCallTypeSelecion({ filters: {} }));
    }

    setValue(callTypeId);
  };

  const selectProps = {
    style: {
      width: "100%",
    },
    options,
    value,
    onChange: (e) => handleOnChange(e),
    placeholder: __('meetings.filters.selectCallType'),
    showArrow: true,
    filterOption: (typed, item) => {
      const contains = String(item.label).toUpperCase().startsWith(String(removeAccents(typed)).toUpperCase())
      return contains;
    },
    onClear: (e) => document.location.reload(),
    className: styles.callTypeSelect
  };

  return <div className={styles.callTypeSelectorContainer}>
    <span className={styles.callTypeFilterTitle}>{__('callType.label')}</span>
    <Select {...selectProps} allowClear={true} disabled={!ready}/>
  </div>
}
