export const getFirstAndLastDayOfWeek = (week, year) => {
  const d = new Date("Jan 01, " + year + " 01:00:00");
  const w = d.getTime() + 604800000 * (week - 1);
  const n1 = new Date(w);
  const n2 = new Date(w + 518400000)

  return [n1, n2]
}

export const toTimeFormat = (v) => {
  if (!v) return 0;
  
  const intPart = String(Math.floor(v)).padStart(2, 0);
  const floatPart = (v - intPart) * 60;

  return `${intPart}:${Math.round(floatPart)}`;
}