import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../app/api";
import __ from "../../app/i18n";
import {
  showGlobalErrors, showGlobalSuccess,
} from "../loading/slice";
import { updatedMeetingLang, updatedMeetingPrivacy } from "../agenda/slice";

const initialState = {
  status: "loading",
  libraries: [],
  fetched: false,
  fetchedLibraryMeetings: false,
  libraryMeetings: [],
  selectedLibrary: null,
  meetingLibraries: [],
  fetchedMeetingLibraries: false,
  selectSaved: false,
  fetchingLangUpdate: false,
};

export const createLibrary = createAsyncThunk(
  "library/create",
  async (payload, { dispatch }) => {
    dispatch(startLoading());

    const response = await Api("createLibrary", payload);
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    dispatch(stopLoading());
    dispatch(loadLibraries())

    return response;
  }
);

export const deleteLibrary = createAsyncThunk(
  "library/delete",
  async (payload, { dispatch }) => {
    const response = await Api("deleteLibrary", payload);
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    } else {
      dispatch(showGlobalSuccess([__("generic.alerts.libraryDeleted")]));
    }



    return response;
  }
);

export const updateLibrariesToMeeting = createAsyncThunk(
  "library/updateLibrariesToMeeting",
  async (payload, { dispatch }) => {
    dispatch(markAsNotSaved())

    dispatch(startLoading());

    const response = await Api("updateLibrariesToMeeting", payload);
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    dispatch(stopLoading());
    dispatch(markAsSaved())

    return response;
  }
);

export const updatePrivacyMeeting = createAsyncThunk(
  "privacy/update",
  async (payload, { dispatch }) => {
    const response = await Api("updatePrivacy", payload);
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    dispatch(stopLoading());

    dispatch(updatedMeetingPrivacy(payload))

    return response;
  }
);

export const updateLangMeeting = createAsyncThunk(
  "lang/update",
  async (payload, { dispatch }) => {
    dispatch(startFetchingLangUpdate());
    const response = await Api("updateLang", payload);
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    } else {
      dispatch(showGlobalSuccess([__('generic.alerts.meetingLangUpdated')]));
    }

    dispatch(stopFetchingLangUpdate());
    dispatch(updatedMeetingLang(payload))

    return response;
  }
);

export const removeMeetingFromLibrary = createAsyncThunk(
  "library/removeMeetingFromLibrary",
  async (payload, { dispatch }) => {
    dispatch(startLoading());

    const response = await Api("removeMeetingFromLibrary", payload);
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    dispatch(loadMeetingsByLibrary(payload.libraryId))

    return response;
  }
);

export const loadLibraries = createAsyncThunk(
  "library/getLibraries",
  async (payload, { dispatch }) => {
    const response = await Api("getLibraries");
    
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    return response;
  }
);
export const loadMeetingLibraries = createAsyncThunk(
  "library/loadMeetingLibraries",
  async (payload, { dispatch }) => {
    const response = await Api("getLibrariesByMeeting", payload);
    
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    return response;
  }
);

export const loadMeetingsByLibrary = createAsyncThunk(
  "library/getMeetingsByLibrary",
  async (payload, { dispatch }) => {

    const response = await Api("getMeetingsByLibrary", payload);
    
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    return response;
  }
);

export const librarySlice = createSlice({
  name: "library",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.status = "loading";
    },
    stopLoading: (state) => {
      state.status = "iddle";
    },
    startFetchingLangUpdate: (state) => {
      state.fetchingLangUpdate = true;
    },
    stopFetchingLangUpdate: (state) => {
      state.fetchingLangUpdate = false;
    },
    markAsSaved: (state) => {
      state.saved = true;
    },
    markAsNotSaved: (state) => {
      state.saved = false;
    },
    clearFetched: (state) => {
      state.fetchedLibraryMeetings = false;
      state.selectedLibrary = null
      state.libraryMeetings = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createLibrary.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadLibraries.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createLibrary.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(loadLibraries.fulfilled, (state, action) => {
        state.status = 'idle';
        state.libraries = action.payload;
        state.fetched = true;
      })
      .addCase(loadMeetingLibraries.fulfilled, (state, action) => {
        state.meetingLibraries = action.payload;
      })

      .addCase(loadMeetingsByLibrary.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadMeetingsByLibrary.fulfilled, (state, action) => {
        state.status = 'idle';
        state.libraryMeetings = action.payload.meetings;
        state.selectedLibrary = action.payload.library;
        state.fetchedLibraryMeetings = true;
      })

      .addCase(deleteLibrary.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteLibrary.fulfilled, (state, action) => {
        state.status = 'idle';
        state.libraries = action.payload;
      })
  },
});

export const { clearFetched, startLoading, stopLoading, markAsSaved, markAsNotSaved, startFetchingLangUpdate, stopFetchingLangUpdate } = librarySlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectLibraries = (state) => state.library.libraries;
export const selectIsFetching = (state) => state.library.status === 'loading';
export const selectIsFetchingLangUpdate = (state) => state.library.fetchingLangUpdate;
export const selectFetchedLibraries = (state) => state.library.fetched;
export const selectFetchedLibraryMeetings = (state) => state.library.fetchedLibraryMeetings;
export const selectLibraryMeetings = (state) => state.library.libraryMeetings;
export const selectSelectedLibrary = (state) => state.library.selectedLibrary;
export const selectedFetchedMeetingLibraries = (state) => state.library.fetchedLibraryMeetings;
export const selectMeetingLibraries = (state) => state.library.meetingLibraries;
export const selectSaved = (state) => state.library.saved;

export default librarySlice.reducer;
