import { Col, Menu, Row, Typography, message } from "antd";
import styles from "../meeting/MeetingDetailHeader.module.css";
import moment from "moment";
import {
  EyeOutlined,
  TeamOutlined,
  FolderAddOutlined,
  TagOutlined,
  DeleteOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { SamuScoreItem } from "../meetings/SamuScoreItem";
import MeetingTags from "../tags/MeetingTags";

export default function ShareMeetingDetailHeader({ meeting }) {
  const alert = () => {
    message.warning("Funcionalidad solo disponible para usuarios.");
  };

  return (
    <Row className={styles.container + " meeting-header-detail-container"}>
      <Col sm={15}>
        <SamuScoreItem size={50} meeting={meeting} />
        {meeting.dateFrom ? (
          <span className={styles.textContainer}>
            <Typography.Title
              style={{ color: "#1791ff" }}
              level={2}
              className={styles.meetingName}
            >
              {meeting.name}
              <MeetingTags meeting={meeting} />
            </Typography.Title>
            <Typography.Text className={styles.meetingDate} type="secondary">
              {moment(meeting.dateFrom).format("Do").replace("º", "") +
                " de " +
                moment(meeting.dateFrom).format("MMMM") +
                " del " +
                moment(meeting.dateFrom).format("YYYY")}{" "}
              ⋅ {moment(meeting.dateFrom).format("HH:mm")}
            </Typography.Text>
          </span>
        ) : (
          <></>
        )}
      </Col>

      <Col sm={9} pul={"right"}>
        <Menu style={{ textAlign: "right" }}>
          <Menu.Item
            style={{ width: "auto", display: "inline-block" }}
            key="visits-section"
            icon={<EyeOutlined className={styles.menuItemLogo} />}
            onClick={alert}
          />

          <Menu.Item
            style={{ width: "auto", display: "inline-block" }}
            key="comments-section"
            icon={<TeamOutlined className={styles.menuItemLogo} />}
            onClick={alert}
          />

          <Menu.Item
            style={{ width: "auto", display: "inline-block" }}
            key="add-to-library-meeting"
            icon={
              <FolderAddOutlined
                className={styles.menuItemLogo}
                key="ellipsis"
              />
            }
            onClick={alert}
          />

          <Menu.Item
            style={{ width: "auto", display: "inline-block" }}
            key="add-tag-meeting"
            icon={
              <TagOutlined className={styles.menuItemLogo} key="ellipsis" />
            }
            onClick={alert}
          />

          <Menu.Item
            style={{ width: "auto", display: "inline-block" }}
            key="privacy-meeting"
            icon={<LockOutlined className={styles.menuItemLogo} />}
            onClick={alert}
          />

          <Menu.Item
            style={{ width: "auto", display: "inline-block" }}
            key="delete-meeting"
            icon={<DeleteOutlined className={styles.menuItemLogo} />}
            onClick={alert}
          />
        </Menu>
      </Col>
    </Row>
  );
}
