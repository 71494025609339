import AnalyticBar from "./AnalyticBar";
import { Typography } from "antd";
import AnalyticsTabContent from "./AnalyticsTabContent";
import PeriodGraphs from "./PeriodGraphs";

export default function TotalCallVolumeTab({ analyticsState }) {
  const mode = "fullscreen";

  return (
    <AnalyticsTabContent>
      <div style={mode === "half" ? { display: "flex" } : {}}>
        <div className={mode === "half" ? "tabContent2Items" : ""}>
          <Typography.Title
            className={"analyticsTabTitle"}
            level={3}
            style={{ marginBottom: "15px" }}
          >
            Reuniones grabadas por usuario
          </Typography.Title>
          <AnalyticBar
            className={"dtabContent2Items"}
            userAnalytics={analyticsState.totalCallVolume.volumes}
            avg={analyticsState.totalCallVolume.avg}
            itemKeyName={"Total del usuario"}
          />
        </div>
        <PeriodGraphs title={'Evolución de las reuniones grabadas por usuario'} userAnalytics={analyticsState.totalCallVolume.periods}/>
      </div>
    </AnalyticsTabContent>
  );
}
