import { useSelector } from "react-redux";
import __ from "../../app/i18n";
import Section from "../meeting/sections/Section";
import { selectMeetingTabs } from "../meeting/slice";
import HubspotTab from "./Hubspot/Tab";
import ExtractorTab from "./Extractor/Tab";

const AppTabsContent = (props) => {
  const meetingTabs = useSelector(selectMeetingTabs);

  return meetingTabs.map((tab, index) => {
    const map = {
      "65b05066b9d8ab7dca53b669": <HubspotTab key={`hubspot-tab-${index}`} data={tab} />,
      "65b0500ab9d8ab7dca53b667": <ExtractorTab key={`extractor-tab-${index}`} data={tab} />,
    };

    const c = map[tab.type];

    if (c) {
      return c;
    }
    return <GenericTabContent key={`generic-tab-${index}`} data={tab} />;
  });
};

const GenericTabContent = ({ data }) => {
  return (
    <Section sectionKey={data.id} title={data.name}>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </Section>
  );
};

export default AppTabsContent;
