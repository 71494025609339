import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../app/api";
import { showGlobalErrors } from "../loading/slice";
import { updateMeetingTags } from "../meetings/filters/slice";
import { updateCurrentMeetingTags } from "../meeting/slice";

const initialState = {
  status: "iddle",
  tags: [],
  fetched: false,
};

export const createTag = createAsyncThunk(
  "tag/create",
  async (payload, { dispatch }) => {
    dispatch(startLoading());

    const response = await Api("createTag", payload);
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    dispatch(stopLoading());

    return response;
  }
);

export const updateTags = createAsyncThunk(
  "tag/updateTags",
  async (payload, { dispatch }) => {
    dispatch(startLoading());

    const response = await Api("updateTags", payload);
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    dispatch(
      updateMeetingTags({ tags: response, meetingId: payload.meetingId })
    );

    dispatch(
      updateCurrentMeetingTags({ tags: response, meetingId: payload.meetingId })
    );

    dispatch(stopLoading());
    dispatch(markAsSaved());

    return response;
  }
);

export const loadTags = createAsyncThunk(
  "tags/loadTags",
  async (payload, { dispatch }) => {
    const response = await Api("getTags");

    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    return response;
  }
);

export const tagSlice = createSlice({
  name: "tag",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.status = "loading";
    },
    stopLoading: (state) => {
      state.status = "iddle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadTags.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadTags.fulfilled, (state, action) => {
        state.status = "idle";
        state.tags = action.payload;
      })
      .addCase(createTag.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createTag.fulfilled, (state, action) => {
        state.status = "idle";
        state.tags = action.payload;
      });
  },
});

export const {
  clearFetched,
  startLoading,
  stopLoading,
  markAsSaved,
  markAsNotSaved,
} = tagSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectTags = (state) => state.tag.tags;
export const selectIsFetching = (state) => state.tag.status === "loading";

export default tagSlice.reducer;
