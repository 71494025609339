import styles from './meetings.module.css';
import logo from "../../brand/Isologotype/samu_white_dark_bckgrnd.png";
import {
  Video,
} from "tabler-icons-react";

export default function SamuThumb () {
  return <div className={styles.samuThumb}>
    <img src={logo} width={'100px'}/>
    <Video style={{ position: "relative", right: "-10px" }}/>
  </div>
}