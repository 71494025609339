import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateAppAndSearch,
  selectedAppFilters,
} from "../../meetings/filters/slice";
import { Alert, Button, Select } from "antd";
import __ from "../../../app/i18n";
import { prettyLabel } from "./Tab";
import NumberFilter from "../Filter/NumberFilter";
import { upperFirst } from "lodash";

export default function Filters(props) {
  const dispatch = useDispatch();
  const appFilters = useSelector(selectedAppFilters);
  let currentValues = {};
  if (
    Object.values(appFilters).length &&
    appFilters[props.id] &&
    appFilters[props.id].values
  ) {
    currentValues = appFilters[props.id].values;
  }

  const [values, setValues] = useState(currentValues);

  const handleClick = () => {
    const appFilters = {
      'extractor': {
        values,
      },
    };

    dispatch(updateAppAndSearch({ appId: 'extrtactor', appFilters }));
  };

  const handleUpdateFilter = (a, b, c) => {
    const v = {
      ...values,
      [a]: b,
    };

    setValues(v);
  };

  const EnumFilter = (props) => {
    const selectOptions = [];

    const ordered = [...props.options].sort((a, b) =>
      a.label < b.label ? -1 : 1
    );
    for (const o of ordered) {
      selectOptions.push(<Select.Option key={o.id}>{o.label}</Select.Option>);
    }

    return (
      <>
        <div style={{ padding: "20px 0", fontWeight: "initial" }}>
          {upperFirst(prettyLabel(props.optionKey))}
        </div>
        <Select
          allowClear={true}
          style={{
            display: "inline-block",
            width: "100%",
          }}
          value={values[props.optionKey]}
          onChange={(v) => handleUpdateFilter(props.optionKey, v)}
        >
          {selectOptions}
        </Select>
      </>
    );
  };

  const NoFilters = () => {
    return (
      <div style={{ marginBottom: "15px" }}>
        <Alert
          message={
            "Aquí podras ver los filtros de las propiedades del tipo ENUM."
          }
          type="info"
          showIcon
        />
      </div>
    );
  };

  return (
    <div style={{ padding: "10px" }}>
      {props.properties.length ? (
        <>
          {props.properties.map((p) =>
            p.number ? (
              <NumberFilter
                appName={"extractor"}
                appId={props.id}
                optionKey={p.optionKey}
                onUpdate={handleUpdateFilter}
                label={upperFirst(prettyLabel(p.optionKey))}
              />
            ) : (
              <EnumFilter {...p} />
            )
          )}
          <Button
            type="primary"
            style={{ marginTop: "15px", width: "100%" }}
            onClick={handleClick}
          >
            {__("meetings.filters.apply")}
          </Button>
        </>
      ) : (
        <NoFilters />
      )}
    </div>
  );
}
