import { Comment, Tooltip } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { List, AutoSizer } from "react-virtualized";
import {
  getTimestampOfMessageToPlay,
  removeAccents,
  secondsToHHMMSS
} from "../../../../app/utils";
import styles from "../../../meetings/meetings.module.css";
import {
  moveVideo,
  selectCurrentDisplayingMessage,
  selectParticipants,
  selectTranscription,
} from "../../slice";
import { useEffect, useRef, useState } from "react";

const filterMessagesByQuery = (messages, q) => {
  if (!q) {
    return messages;
  }

  return messages.filter((m) =>
    String(removeAccents(m.text)).toUpperCase().includes(q.toUpperCase())
  );
};

export default function TranscriptionList({ q, meeting, style = {}, onClear }) {
  const transcription = useSelector(selectTranscription);
  const participants = useSelector(selectParticipants);
  const dispatch = useDispatch();
  const filteredMessages = filterMessagesByQuery(transcription, q);
  const currentDisplayingMessage = useSelector(selectCurrentDisplayingMessage);
  const transcriptionListRef = useRef()
  const [scrollToIndex, setScrollToIndex] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    if (scrollToIndex !== -1 && !q) {
      transcriptionListRef.current.scrollToRow(scrollToIndex + 3);

      setScrollToIndex(-1);
    }
  }, [q, scrollToIndex]);

  useEffect(() => {
    if (!q && currentDisplayingMessage) {

      const index = transcription.findIndex(i => i.id >= currentDisplayingMessage.id);
      if (index) {
        setScrollToIndex(index);
      }

    }
  }, [q, currentDisplayingMessage]);

  const mapMatch = (text) => {
    text = removeAccents(text);
    q = removeAccents(q);
    const start = String(text).toUpperCase().indexOf(q.toUpperCase());

    if (start === -1) {
      return <span>{text}</span>;
    }

    return (
      <>
        <span>{text.split("").splice(0, start)}</span>
        <b>{text.split("").splice(start, q.length)}</b>
        <span>{text.split("").splice(start + q.length, text.length)}</span>
      </>
    );
  };

  const playLine = (item) => {
    const timestamp = getTimestampOfMessageToPlay(meeting, item);

    dispatch(moveVideo(timestamp));
    document.querySelector('video').play();

    if (q) {
      onClear();

      const index = transcription.findIndex(i => i === item);
      
      setSelectedIndex(index);
      setScrollToIndex(index);
    }
  };

  const nonSelectedStyles = {
    cursor: "pointer",
  };

  const selectedStyles = {
    cursor: "pointer",
  };

  const renderName = (user) => {
    const color = participants.find((p) => p.name === user.name).color;
    return <>{initials({ name: user.name, color })}</>;
  };

  const initials = ({ name, color }) => {
    const initials = name
      .split(" ")
      .map((w) => w.charAt(0))
      .join("");

    return (
      <span className={styles.initialsBuble} style={{ backgroundColor: color }}>
        {initials}
      </span>
    );
  };

  function rowRenderer({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }) {
    const item = filteredMessages[index];
    const { text, startAt, who, image } = item;

    let time;
    if (['MICROSOFT', 'GOOGLE'].includes(meeting.provider)) {
      time = secondsToHHMMSS(getTimestampOfMessageToPlay(meeting, item));
    } else {
      time = secondsToHHMMSS(startAt);
    }

    if (!q && (index === selectedIndex)) {
      style.fontWeight = 'bold';
      style.color = '#1a91ff';
    } else {
      style.fontWeight = 'inherit';
      style.color = 'inherit';
    }

    if (!q && ((currentDisplayingMessage && currentDisplayingMessage.id === item.id))) {
      style.boxShadow = '1px 1px 10px #DDD';
    } else {
      style.boxShadow = 'none';
    }

    return (
      <div key={key} style={{...style, cursor: "pointer !important"}}>
        <Comment
          onClick={() => playLine(item)}
          author={<a>{who}</a>}
          avatar={renderName({ name: who, image })}
          content={<p>{mapMatch(text)}</p>}
          datetime={
            <Tooltip title={time}>
              <span>{time}</span>
            </Tooltip>
          }
        />
      </div>
    );
  }

  return (
    <div style={{ flex: "1 1 auto", height: "80vh" }}>
      <AutoSizer>
        {({ height, width }) => (
          <List
            ref={transcriptionListRef}
            className="List"
            height={height}
            rowCount={filteredMessages.length}
            style={{ cursor: "pointer !important" }}
            rowHeight={({ index }) => {
              const lines = parseInt(filteredMessages[index].text.length / 90) + 1;
              let margin = 0;
              if (lines > 1) {
                margin = lines * 2.75;
              }

              return 50 + (lines * 20) + margin;
            }}
            width={width}
            rowRenderer={rowRenderer}
          />
        )}
      </AutoSizer>
    </div>
  );
}