import { Button, Input } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { selectedName, updateAndSearch } from "../slice";
import __ from "../../../../app/i18n";
import { useState } from "react";


export default function NameFilter() {
  const searchedName = useSelector(selectedName)
  const dispatch = useDispatch();
  const [name, setName] = useState(searchedName);

  const handleClick = () => {
    dispatch(updateAndSearch({ name }));
  };

  return (
    <div style={{ padding: "10px" }}>
      <Input onChange={(e) => setName(e.target.value)} value={name}/>

      <Button
        type="primary"
        style={{ marginTop: "5px", width: "100%" }}
        onClick={handleClick}
      >
        {__("meetings.filters.apply")}
      </Button>
    </div>
  );
}
