import { Space, Alert } from "antd";
import __ from "../../../app/i18n";

const TrialExpiredMessage = () => {
  return (
    <Space
      direction="vertical"
      style={{
        marginBottom: 15,
        display: "block",
        maxWidth: "700px",
      }}
    >
      <Alert
        message={
          <div style={{ paddingLeft: "10px" }}> El periodo de trial ha expirado.</div>
        }
        type="error"
        showIcon
        banner
      />
    </Space>
  );
};
export default TrialExpiredMessage;
