import { Col, Row } from "antd";
import { useEffect, useRef } from "react";
import MediaControls from "./MediaControls";
import MediaCC from "./MediaCC";
import { useDispatch, useSelector } from "react-redux";
import {
  markAsSeen,
  selectOpenDealSection,
  selectOpenTeamSection,
  selectVideoTime,
  setVideoDuration,
} from "./slice";
import { getTimestampOfMessageToPlay } from "../../app/utils";
import HubspotPloter from "./hubspot.png";
import MeetingTimelineContainer from "./MeetingTimelineContainer";
import moment from "moment";

export default function MeetingMedia({ meeting, time, span }) {
  const dispatch = useDispatch();
  const videoTime = useSelector(selectVideoTime);
  const openDealSection = useSelector(selectOpenDealSection);
  const openTeamSection = useSelector(selectOpenTeamSection);
  const { videoUrl } = meeting;
  const ref = useRef();

  const onClickTimeline = (item) => {
    const timestamp = getTimestampOfMessageToPlay(meeting, item);

    if (!String(timestamp).includes(':')) {
      document.querySelector("video").currentTime = timestamp;
    }
  };

  useEffect(() => {
    if (videoTime) {
      ref.current.currentTime = videoTime;
    }
  }, [ref && ref.current, videoTime]);

  useEffect(() => {
    if (time) {
      onClickTimeline({ startAt: time });
      document.querySelector("video").play();
    }
  }, [ref && ref.current]);

  useEffect(() => {
    if (!meeting.seen) {
      const timer = setTimeout(() => {
        dispatch(markAsSeen(meeting.id));
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [meeting]);

  const handleLoadedMetadata = () => {
    let duration = ref.current.duration;
    if (ref.current.duration === Infinity && Array.isArray(meeting.transcription) && meeting.transcription.length) {
      const first = meeting.transcription[0];
      const last = meeting.transcription[meeting.transcription.length - 1];

      duration = Math.abs(moment(last.endAt).diff(first.startAt, 'second'));
    }

    dispatch(setVideoDuration(duration));
  };

  span = span || 21;
  if (openDealSection) {
    span = 12;
  } else if (openTeamSection) {
    span = 13;
  }

  let mediaProps = {};
  if (meeting.provider === "HUBSPOT") {
    mediaProps.poster = HubspotPloter;
  }

  return (
    <Col span={span} offset={1}>
      <Row>
        <Col span={24}>
          <video
            style={{ width: "100%", marginTop: "30px", maxHeight: "65vh" }}
            src={videoUrl}
            ref={ref}
            autoPlay={true}
            onLoadedMetadata={handleLoadedMetadata}
            controls={true}
            {...mediaProps}
          />
          <MediaControls meeting={meeting} videoRef={ref} />
          <MediaCC meeting={meeting} videoRef={ref} />
          <MeetingTimelineContainer
            key="meeting_timeline_container"
            style={{ marginTop: "10px" }}
            meeting={meeting}
            onClickTimeline={onClickTimeline}
          />
        </Col>
      </Row>
    </Col>
  );
}
