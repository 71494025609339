import { useDispatch, useSelector } from "react-redux";
import { closeDealSection, closeTeamSection, selectOpenDealSection, selectOpenTeamSection } from "../slice";
import { CloseCircleOutlined } from '@ant-design/icons';

import { Typography } from "antd";

const Section = ({ title, children, sectionKey, position = 'deal'}) => {
  const dispatch = useDispatch();
  
  const selectors = {
    'deal': { selector: useSelector(selectOpenDealSection), close: closeDealSection },
    'team': { selector: useSelector(selectOpenTeamSection), close: closeTeamSection },
  };

  const openSection = selectors[position].selector;

  const onClose = () => {
    const action = selectors[position].close;
    
    dispatch(action());
  };

  if (openSection !== sectionKey) {
    return <></>
  }
  return (
    <div key={sectionKey} className={"section-content-container " + sectionKey + '-section-container'}>
      <Typography.Title level={4} className="section-title">
        { title }
        <CloseCircleOutlined style={{right: "18px", top: "18px", position: "absolute", cursor: "pointer"}} onClick={onClose}/>
      </Typography.Title>

      <div className="section-content-scroll">
        { children }
      </div>
    </div>
  );
};

export default Section;
