import { useDispatch, useSelector } from "react-redux";
import { Tabs, message } from "antd";
import __ from "../../../../app/i18n";
import { useEffect, useState } from "react";
import Section from "../Section";

import {
  selectError,
} from "./slice";
import SpicedTab from "./tabs/Spiced";
import SummaryTab from "./tabs/Summary";
import FollowUpEmailTab from "./tabs/FollowUpEmail";
import { getConfig, selectIsChatWithMeetingEnabled, selectIsFollowUpEnabled } from "../../../session/slice";
import ChatTab from "./tabs/Chat";
import MinuteTab from "./tabs/Minute";
import { selectMeetingMinute } from "../../slice";

let initialTabs = [
  {
    label: "Chat",
    content: <ChatTab />,
  },
  {
    label: "Spiced",
    content: <SpicedTab />,
  },
  {
    label: "Resumen detallado",
    content: <SummaryTab />,
  },
];

const renderMapper = (tabs) => {
  const currentTabs = [...tabs];

  return currentTabs.map((item, i) => {
    const id = String(i + 1);
  
    return {
      label: <span>{item.label}</span>,
      key: id,
      children: item.content,
    };
  });
}
const Ai = () => {
  const requestError = useSelector(selectError);
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const isFollowUpEnabled = useSelector(selectIsFollowUpEnabled);
  const isChatWithMeetingEnabled = useSelector(selectIsChatWithMeetingEnabled);
  const [tabs, setTabs] = useState(initialTabs);
  const [tabsRender, setTabsRender] = useState(renderMapper(initialTabs));
  const [followUpEmailFetched, setFollowUpEmailFetched] = useState(false);
  const minute = useSelector(selectMeetingMinute);

  useEffect(() => {
    if (requestError) {
      messageApi.open({
        type: "error",
        content: __("generic.errors." + requestError),
      });
    }

    dispatch(getConfig());
  }, [requestError]);

  useEffect(() => {
    const tabLabels = [...new Set(tabs.map(t => t.label))];

    if (minute && !tabLabels.includes('Minuta')) {
      let newTabs = tabs;

      newTabs.push({
        label: "Minuta",
        content: <MinuteTab />,
      });

      setTabs();
    }
  }, [minute]);

  useEffect(() => {
    if ((isFollowUpEnabled || isChatWithMeetingEnabled) && !followUpEmailFetched) {
      let newTabs = tabs;

      if (isFollowUpEnabled && !newTabs.find(i => i.label === "Mail de follow up")) {
        newTabs.push({
          label: "Mail de follow up",
          content: <FollowUpEmailTab />,
        });
      }

      setTabs([...new Set(newTabs)]);
      setFollowUpEmailFetched(true);
    }

  }, [isFollowUpEnabled, followUpEmailFetched, isChatWithMeetingEnabled]);

  useEffect(() => {
    if (tabs.length) {
      setTabsRender(renderMapper(tabs));
    }
  }, [tabs]);

  return (
    <Section sectionKey="ai" title={<>{ __("meetings.details.sections.ai")}</>}>
      {contextHolder}
      <Tabs
        defaultActiveKey="2"
        items={tabsRender}
      />
    </Section>
  );
};

export default Ai;
