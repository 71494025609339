import { Button, Form } from "antd";
import _ from "lodash";
import { useState } from "react";
import AppItem from "../AppItem";
import Property from "./Property";

const map = (properties) => {
  return Object.values(properties || {}).reduce((prev, curr, index) => {
    const name = Object.keys(properties)[index];
    const o = {
      type: curr.enum ? "enum" : curr.type,
      name: String(name).toUpperCase().replace(/_/g, ' '),
      description: curr.description,
      values: curr.enum,
    };

    prev.push(o);

    return prev;
  }, []);
};

function App(props) {
  const [enabled, setEnabled] = useState(props.app.enabled);
  const [properties, setProperties] = useState(map(props.app.properties));

  const handleSave = () => {
    const newSettings = {
      ...props.app,
      enabled,
      properties
    };

    props.handleOnSave(newSettings);
  };

  const handleNew = () => {
    setProperties((p) => [
      ...p,
      { name: "example", description: "", type: "enum" },
    ]);
  };

  const onUpdateProperty = ({ index, ...prop }) => {
    const data = [...properties];
    data[index] = prop;

    setProperties(data);
  };

  const onRemove = index => {
    const data = properties.filter((_, i) => i !== index);

    setProperties(data);
  }

  return (
    <AppItem
      app={props.app}
      onToggleStatus={(e) => setEnabled(e)}
      canSave={props.app}
      onSave={handleSave}
      canToggleStatus={props.app}
      enabled={enabled}
    >
      <Form>
        <Button
          type="primary"
          style={{ marginBottom: "15px" }}
          onClick={handleNew}
        >
          Agregar nueva propiedad
        </Button>
        {properties.map((p, i) => (
          <Property {...p} index={i} onUpdateProperty={onUpdateProperty} onRemove={onRemove}/>
        ))}
      </Form>
    </AppItem>
  );
}

export default App;
