import { Button, Slider, Typography } from "antd";
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import __ from "../../../../app/i18n";
import { selectedDuration, updateDurationFilter } from "../slice";
const { Text } = Typography;

export default function HostsFilter() {
  const [hasChanges, setHasChanges] = useState(false);
  const selectorDuration = useSelector(selectedDuration)
  let [changes, setChanges] = useState(selectorDuration);
  const duration = useSelector(selectedDuration);
  const { from, to } = duration || {};
  const dispatch = useDispatch();
  const defaults = { from: 5, to: 180 };

  const handleClick = () => {
    dispatch(updateDurationFilter(changes));
    setHasChanges(false)
  }
  const onChangeDuration = ([from, to]) => {
    setHasChanges(true)
    setChanges({ from, to });
  }

  useEffect(() => {
    setChanges(selectorDuration)
  }, [selectorDuration]);

  const currentFrom = Number.isInteger((changes || {}).from) ? (changes || {}).from : defaults.from;
  const currentTo = Number.isInteger((changes || {}).to) ? (changes || {}).to : defaults.to;

  return (
    <div style={{ margin: "15px 15px" }}>
      <Text type="secondary">{ 'Entre ' + (currentFrom) + ' y ' + (currentTo) + ' minutos.'}</Text>
      <Slider range defaultValue={[from || defaults.from, to || defaults.to]} max={180} onAfterChange={onChangeDuration} />
      { hasChanges ? <Button
        type="primary"
        style={{ marginTop: "5px", width: "100%" }}
        onClick={handleClick}
      >
        { __('meetings.filters.apply') }
      </Button> : '' }
      
    </div>
  );
}
