import { Modal } from "antd";

function DemoModal({ open, close }) {
  return (
    <Modal
      centered
      width={"1024px"}
      open={open}
      onCancel={close}
      okButtonProps={false}
      style={{ paddingTop: "15px" }}
      closeIcon={false}
      cancelButtonProps={{ display: "none" }}
    >
      <iframe
        width={"100%"}
        height={"800px"}
        src="https://meetings.hubspot.com/andrulo/demo-samu"
        style={{ border: "none" }}
      />
    </Modal>
  );
}

export default DemoModal;
