import { Button } from "antd";
import { useSelector } from "react-redux";
import { SaveOutlined } from '@ant-design/icons'
import { selectFiltersApplied, selectHasFiltersApplied, selectedCallType } from "../slice";
import __ from "../../../../app/i18n";
import { useEffect, useState } from "react";
import CallTypesModal from "./CallTypesModal";

const allExceptCallTyoe = (obj = {}) => {
  let { callType, ...data } = obj;

  return data;
}

export default function SaveCallType() {
  const [isModalOpen, setModalOpen] = useState(false);
  const callType = useSelector(selectedCallType);
  const hasFiltersApplied = useSelector(selectHasFiltersApplied);
  const filtersApplied = useSelector(selectFiltersApplied);

  const shouldShowSaveButton = () => {
    if (!hasFiltersApplied) {
      return false
    }

    if (!callType) {
      return true;
    }

    if (JSON.stringify(allExceptCallTyoe(callType.filters)) !== JSON.stringify(allExceptCallTyoe(filtersApplied))) {
      return true
    }

    return false;
  };

  if (shouldShowSaveButton()) {
    return (
      <>
      <Button
      icon={<SaveOutlined />}
        style={{
          marginBottom: "10px",
          borderRadius: "50px",
          textAlign: "right",
          color: "#fff",
          backgroundColor: "#1791ff",
          float: "right"
        }}
        onClick={() => setModalOpen(true)}
      >
      {__("meetings.filters.save")}
      </Button>
      <CallTypesModal open={isModalOpen} onClose={() => setModalOpen(false)} />
      </>
    );
  }
}
