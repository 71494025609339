import { CopyOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { selectMeeting } from "../../../slice";
import { Button, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { copyTextToClipboard, fixGPTEncoding } from "../../../../../app/utils";
import Api from "../../../../../app/api";
import BrainLoader from "../BrainLoader";
import __ from "../../../../../app/i18n";
import { analytics } from "../../../../../hooks/tracker";
import OpenAiDisclaimer from "./OpenAiDisclaimer";

function clearItem(texto) {
  return fixGPTEncoding(
    texto.replace(/\\u([\d\w]{4})/gi, function (match, grp) {
      return String.fromCharCode(parseInt(grp, 16));
    })
  );
}

const cleanContent = (c) => {
  c.subject = clearItem(c.subject);
  c.body = clearItem(c.body);

  return c;
};

const renderSection = (item, index, items) => {
  return item
    .trim()
    .split(":**")
    .map((i) => {
      if (String(i).startsWith("**")) {
        return (
          <>
            <li>{i.replace("**", "")}</li>
            <br />
          </>
        );
      } else {
        if (index === items.length - 2) {
          return (
            <>
              <br />
              <li>{i}</li>
            </>
          );
        }

        return (
          <>
            <li>{i}</li>
            <br />
          </>
        );
      }
    });
};

const SummaryTab = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const meeting = useSelector(selectMeeting);
  const [fetched, setFetched] = useState(false);
  const [isTriggered, setIsTriggered] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [content, setContent] = useState({});

  useEffect(() => {
    (async function () {
      if (!fetched && meeting) {
        setIsFetching(true);
        const reportData = await Api("getAIReport", {
          id: meeting.id,
          reportType: "followUpEmail",
        });
        setIsFetching(false);

        setFetched(true);

        if (reportData) {
          setContent(cleanContent(reportData.content));
        }
      }
    })();
  }, [fetched, meeting]);

  const handleCopy = (text) => {
    copyTextToClipboard(text);
    messageApi.open({
      type: "success",
      content: "Copiado al portapapeles",
    });
  };

  if (content && content.body && content.subject) {
    return (
      <div>
        {contextHolder}
        <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
          <Typography.Title level={5}>
            Subject del e-mail
            <CopyOutlined
              onClick={() => handleCopy(content.subject)}
              style={{
                fontSize: "18px",
                cursor: "pointer",
                marginLeft: "15px",
              }}
            />
          </Typography.Title>
          <>
            <Typography.Text type="secondary">
              {content.subject}
            </Typography.Text>
          </>

          <>
            <Typography.Title style={{ marginTop: "25px" }} level={5}>
              Body del e-mail
              <CopyOutlined
                onClick={() => handleCopy(content.body)}
                style={{
                  fontSize: "18px",
                  cursor: "pointer !important",
                  marginLeft: "15px",
                }}
              />
            </Typography.Title>

            {content.body
              .split("\n")
              .filter((item) => item.trim() !== "")
              .map((item, a, b) => {
                return (
                  <Typography.Text
                    type="secondary"
                    style={{ marginBottom: "5px" }}
                  >
                    {renderSection(item.trim(), a, b)}
                  </Typography.Text>
                );
              })}
          </>
        </ul>
      </div>
    );
  }

  const handle = async () => {
    setIsFetching(true);
    setIsTriggered(true);
    const reportData = await Api("generateAIReport", {
      id: meeting.id,
      reportType: "followUpEmail",
    });

    if (reportData) {
      if (reportData.status === "error") {
        messageApi.open({
          type: "error",
          content: __("generic.errors." + reportData.code),
        });
      } else {
        setContent(reportData.content);
      }
    }

    if (analytics) {
      const { id, name, client, dateFrom } = meeting;

      analytics.track("FollowUp Report Requested", {
        id,
        name,
        client,
        dateFrom,
        reportData,
      });
    }

    setIsFetching(false);
  };

  if (isFetching) {
    return (
      <>
        <OpenAiDisclaimer />
        <div style={{ marginTop: "30%", textAlign: "center" }}>
          {contextHolder}
          <BrainLoader />
          { isTriggered ? <div style={{ marginTop: "15px" }}>{__('ai.waitingForOpenAi')}</div> : '' }
        </div>
      </>
    );
  }

  return (
    <>
      {contextHolder}
      <OpenAiDisclaimer />

      <Button type="primary" style={{ width: "100%" }} onClick={handle}>
        Analizar
      </Button>
    </>
  );
};

export default SummaryTab;
