import { Form, Input, Modal } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import __ from "../../../../app/i18n";
import { createCallTypeFromFilters } from "../slice";
import { closeNewModal, selectIsNewCallTypeModalOpen } from "./slice";

const NewCallTypeModal = ({ parentClose, children }) => {
  const dispatch = useDispatch();
  const [callTypeName, setCallTypeName] = useState("");
  const open = useSelector(selectIsNewCallTypeModalOpen);

  const handleCreation = () => {
    if (callTypeName) {
      dispatch(createCallTypeFromFilters({ name: callTypeName }));
    }

    parentClose();
    
    onClose();
  };

  const onClose = () => {
    dispatch(closeNewModal());
  };

  return (
    <Modal
      title={__("callType.new")}
      okText={__("callType.create")}
      centered
      open={open}
      onOk={() => handleCreation()}
      onCancel={onClose}
    >
      <div>{children}</div>
      <Form.Item
        label={__("callType.callTypeName")}
        required
        tooltip={__("generic.required.callType")}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input required value={callTypeName} onChange={(e) => setCallTypeName(e.target.value)} placeholder={__("callType.newPlaceHolder")} />
      </Form.Item>
    </Modal>
  );
};

export default NewCallTypeModal;
