import es from './es.json';
import en from './en.json';
import get from 'lodash/get';

const books = { es, en };

export default function __(key) {
  /**
  @todo - By default now it's spanish.
  let lang = String(navigator.language || navigator.userLanguage).split('-')[0];

  if (!Object.keys(books).includes(lang)) {
    lang = 'en';
  }
  **/
 
  let lang = 'es';

  const word = get(books[lang], key);

  return word || key;
}