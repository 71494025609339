import { Button, Modal, Select, Space, Spin } from "antd";
import { useEffect, useState } from "react";
import {
  selectIsFetching,
  loadTags,
  selectTags,
  updateTags,
} from "./slice";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import __ from "../../app/i18n";
import { removeAccents } from "../../app/utils";
import NewTagModal from "./NewTagModal";

const TagsModal = ({ meeting, open, onClose }) => {
  const [newTagIsOpen, setNewTagIsOpen] = useState(false);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const tags = useSelector(selectTags);
  const isFetching = useSelector(selectIsFetching);
  const [value, setValue] = useState([]);
  const [options, setOptions] = useState([]);
  const meetingTags = meeting?.tags;

  useEffect(() => {
    if (open) {
      loadData();
    }
  }, [dispatch, meeting, open]);

  const loadData = () => {
    setLoading(true);
    dispatch(loadTags());
  };

  useEffect(() => {
    if (Array.isArray(tags)) {
      setLoading(false);

      const inUse = (meetingTags || []).map(t => t._id);;

      const newOptions = [];
      tags.forEach((tag) => {
        newOptions.push({
          value: tag._id,
          label: tag.name,
        });
      });

      setValue(inUse);
      setOptions(newOptions);
    }
    setLoading(false);
  }, [tags]);

  const selectProps = {
    mode: "multiple",
    style: {
      width: "100%",
      maxWidth: "90%",
      marginRight: "5px",
    },
    value,
    options,
    onChange: (newValue) => {
      setValue(newValue);
    },
    maxTagCount: "responsive",
    showArrow: true,
    filterOption: (typed, item) => {
      const contains = String(item.label)
        .toUpperCase()
        .startsWith(String(removeAccents(typed)).toUpperCase());
      return contains;
    },
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 100 }} spin />;

  const handleSaveChanges = () => {
    const payload = {
      meetingId: meeting.id,
      tags: value
    };

    dispatch(updateTags(payload));
    onClose();
  };

  if (isLoading || isFetching) {
    return (
      <Modal
        title={__("tag.addTagToMeeting")}
        centered
        open={open}
        onOk={() => onClose()}
      >
        <div style={{ textAlign: "center" }}>
          <Spin indicator={antIcon} />
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      title={__("tag.addTagToMeeting")}
      centered
      open={open}
      onOk={() => handleSaveChanges()}
      okText={__("library.saveChanges")}
      onCancel={onClose}
    >
      <>
        <NewTagModal open={newTagIsOpen} onClose={() => { setNewTagIsOpen(false); loadData() }}/>
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
        >
          <div>
            <Select {...selectProps} />
            <Button onClick={() => setNewTagIsOpen(true)}>+</Button>
          </div>
        </Space>
      </>
    </Modal>
  );
};

export default TagsModal;
