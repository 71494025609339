import { DashboardOutlined } from "@ant-design/icons";
import { useState } from "react";
import { Tooltip } from "antd";

export default function VelocityControl({ videoRef }) {
  const [currentSpeed, setSpeed] = useState(1);

  const handleClick = () => {
    let newSpeed = currentSpeed + 0.25;
    if (newSpeed > 2.5) {
      newSpeed = 1;
    }

    setSpeed(newSpeed);
    videoRef.current.playbackRate = newSpeed;
  };

  return (
    <Tooltip placement="top" color="blue" title={currentSpeed + 'x'}>
      <DashboardOutlined
        style={{ fontSize: "40px", margin: "0 15px" }}
        onClick={handleClick}
      />
    </Tooltip>
  );
}
