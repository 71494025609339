import { Alert } from "antd";
import { useState } from "react";

const closedTabInformationKey = 'closedTabInformation';

const TabInformation = () => {
  const [closedMessage, setClosedMessage] = useState(!!localStorage.getItem(closedTabInformationKey));

  if (!closedMessage) {
    return (
      <Alert
        message="Analítica"
        description="Para los siguientes datos, se tomaron en cuenta todas las llamadas que fueron concurridas por al menos dos personas y duraron mas de 5 minutos, con el objetivo de aportar datos significativos en el análisis de las llamadas."
        type="info"
        showIcon
        closable
        style={{ marginBottom: "30px" }}
        onClose={() => {
          localStorage.setItem(closedTabInformationKey, true);
          setClosedMessage(false);
        }}
      />
    );
  }
};

export default TabInformation;
