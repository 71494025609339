import { Input, Slider, Typography } from "antd";
import { useEffect, useState } from "react";
import __ from "../../../app/i18n";
import { useSelector } from "react-redux";
import { selectedAppFilters } from "../../meetings/filters/slice";
import _ from "lodash";

const appFilterValue = (appFilters, appId, optionKey, dir) => {
  try {
    const val = appFilters[appId]["values"][optionKey].range[dir];
    return parseFloat(val);
  } catch (error) {
  }
};

export default function NumberFilter({
  appName,
  appId,
  label,
  optionKey,
  onUpdate,
  extra
}) {
  const appFilters = useSelector(selectedAppFilters);
  
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  useState(() => {
    const defaultFrom = appFilterValue(appFilters, appId, optionKey, "from");
    const defaultTo = appFilterValue(appFilters, appId, optionKey, "to");
    if (defaultFrom) {
      setFrom(defaultFrom);
    }

    if (defaultTo) {
      setTo(defaultTo);
    }
  }, [appFilters])


  const handleUpdate = (dir, value) => {
    if (dir === 'from') {
      setFrom(value)
    } else {
      setTo(value);
    }

    const payload = { from, to, [dir]: value };
    onUpdate(optionKey, { range: payload });
  }

  const reset = () => {
    setFrom();
    setTo(); 

    onUpdate(optionKey, { range: {} });
  };

  return (
    <div>
      <div style={{ padding: "20px 0 20px", fontWeight: "initial" }}>
        <span>{label}</span> { extra } 
        <div>
          <a
            style={{ display: "inline-block", float: "right" }}
            onClick={reset}
          >
            Reset
          </a>
        </div>
      </div>
      <div>
        <Input
          placeholder="Min"
          value={from}
          onChange={(e) => handleUpdate('from', e.target.value)}
          style={{ display: "inline-block", width: "48%", marginRight: "2%" }}
          type="number"
        />
        <Input
          placeholder="Max"
          value={to}
          onChange={(e) => handleUpdate('to', e.target.value)}
          style={{ display: "inline-block", width: "48%", marginLeft: "2%" }}
          type="number"
        />
      </div>
    </div>
  );
}
