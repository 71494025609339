import { Dropdown, Menu, Select } from "antd";
import {
  MoreOutlined,
  FolderAddOutlined,
  TagsOutlined,
  TrophyOutlined,
} from "@ant-design/icons";
import __ from "../../app/i18n";
import { useDispatch, useSelector } from "react-redux";
import { hosts, updateMeetingHost } from "./filters/slice";

const MeetingActions = ({ meeting, onLibrary, onTag }) => {
  const dispatch = useDispatch();
  const sellers = useSelector(hosts);

  const handleUpdateHost = (hostId) => {
    const host = sellers.find((s) => s.id === hostId);
    dispatch(
      updateMeetingHost({
        meetingId: meeting.id,
        hostId: host.id,
        hostName: host.name,
      })
    );
  };

  const sellerOptions = (sellers || []).map((item, index) => ({
    key: `seller#update#${index}`,
    label: item.name,
    value: item.id,
  }));

  const menu = (
    <Menu>
      <Menu.Item
        icon={<FolderAddOutlined style={{ fontSize: 18, color: "#1791ff" }} />}
        onClick={onLibrary}
      >
        Agregar a librería
      </Menu.Item>

      <Menu.Item
        icon={<TagsOutlined style={{ fontSize: 18, color: "#1791ff" }} />}
        onClick={onTag}
      >
        Agregar etiqueta
      </Menu.Item>

      <Menu.SubMenu
        title={__("actions.updateHost")}
        icon={<TrophyOutlined style={{ fontSize: 18, color: "#1791ff" }} />}
      >
        <Menu.Item>
          <Select
            showSearch
            placeholder={__("meetings.filters.selectHost")}
            onMouseDown={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
            onChange={(h) => handleUpdateHost(h)}
            options={sellerOptions}
            optionFilterProp="children"
            filterOption={(input, option) => String(option?.label ?? "").toLowerCase().includes(String(input).toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
          />
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu}>
        <MoreOutlined
          style={{ fontSize: "15px", fontWeight: "bold", color: "#1791ff" }}
        />
      </Dropdown>
    </>
  );
};
export default MeetingActions;
