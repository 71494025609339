import { Badge, Button, Card, Switch, Tooltip } from "antd";
import Meta from "antd/lib/card/Meta";
import styles from "./styles.module.css";
import MeetingTags from "../tags/MeetingTags";
import { selectIsFetching } from "./slice";
import { useSelector } from "react-redux";

export default function AppItem({
  app,
  children,
  onToggleStatus,
  canSave,
  onSave,
  canToggleStatus,
  enabled
}) {
  const isLoading = useSelector(selectIsFetching);

  return (
    <Card
      className={styles.libraryItem}
      style={{ borderTop: "none", width: "100%" }}
      loading={isLoading}
    >
      <Meta
        avatar={<img style={{ width: "50px" }} src={app.icon} />}
        title={
          <>
            {canToggleStatus ? (
              <Switch
                checked={enabled}
                style={{ position: "absolute", top: "15px", right: "15px" }}
                onChange={onToggleStatus}
              />
            ) : (
              ""
            )}

            <div>{app.name}</div>
            <p
              className="ant-card-meta-description"
              style={{ fontSize: "14px", fontWeight: "inherit" }}
            >
              {app.description}
            </p>

            <div style={{ marginLeft: "-10px" }}>
              {app.inUse ? (
                <>
                  <Tooltip title="App instalada">
                    <>
                      <Badge
                        status="success"
                        style={{
                          width: "25px",
                          position: "absolute",
                          right: 0,
                          top: "10px",
                          fontWeight: "10px",
                        }}
                      />
                    </>
                  </Tooltip>
                  <MeetingTags meeting={{ tags: [{ name: "Instalada" }] }} />
                </>
              ) : (
                ""
              )}
            </div>
          </>
        }
        description={
          <div style={{ marginTop: "15px" }}>
            <div>{children}</div>
            {canSave ? (
              <Button
                type="primary"
                style={{
                  width: "50%",
                  display: "block",
                  marginTop: "15px",
                  cursor: "pointer",
                }}
                onClick={() => onSave()}
              >
                Guardar
              </Button>
            ) : (
              <></>
            )}
          </div>
        }
      />
    </Card>
  );
}
