import { PlayCircleTwoTone, DeleteOutlined, ExperimentOutlined, VideoCameraOutlined } from "@ant-design/icons";

import styles from "./meetings.module.css";
import __ from "../../app/i18n";

const MeetingStatus = {
  SCHEDULED: "SCHEDULED", //
  RECORDING: "RECORDING", //
  CANCELED: "CANCELED",
  IN_PROGRESS: "IN_PROGRESS", //
  FAILED: "FAILED",
  RECORDED: "RECORDED", // 
  COMPLETED: "COMPLETED", //
};

export default function MeetingStatusIcon(props) {
  const { status } = props.meeting;

  const icon = {
    [MeetingStatus.COMPLETED]: (
      <span className={styles.avatarMeetingDuration}>
        <PlayCircleTwoTone />
      </span>
    ),
    [MeetingStatus.SCHEDULED]: (
      <span
        className={styles.avatarMeetingDuration}
        style={{ backgroundColor: "#faad14" }}
      >
        {__("meetings.status.scheduled")}
      </span>
    ),
    [MeetingStatus.RECORDING]: (
      <span
        className={styles.avatarMeetingDuration}
        style={{ backgroundColor: "#52c41a" }}
      >
        <VideoCameraOutlined alt={__("meetings.status.inProgress")} /> {" "}
      </span>
    ),
    [MeetingStatus.IN_PROGRESS]: (
      <span
        className={styles.avatarMeetingDuration}
        style={{ backgroundColor: "#faad14" }}
      >

        <VideoCameraOutlined alt={__("meetings.status.inProgress")} /> {" "}
      </span>
    ),
    [MeetingStatus.RECORDED]: (
      <span
        className={styles.avatarMeetingDuration}
        style={{ backgroundColor: "#b014fa" }}
      >
        <ExperimentOutlined /> {" "}
        {__("meetings.status.processing")}
      </span>
    ),
    [MeetingStatus.CANCELED]: (
      <span
        className={styles.avatarMeetingDuration}
        style={{ backgroundColor: "#fa1414" }}
      >
        <DeleteOutlined alt={__("meetings.status.canceled")}/> {" "}
      </span>
    ),
  };
  
  return icon[status];
}
