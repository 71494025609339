import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadCallTypes, openNewModal, selectCallTypes, updateCallType } from "./slice";
import { removeAccents } from "../../../../app/utils";
import { Button, Modal, Select, Space } from "antd";
import __ from "../../../../app/i18n";
import NewCallTypeModal from "./NewCallTypeModal";
import { selectFiltersApplied, selectedCallType } from "../slice";

const allExceptCallTyoe = (obj = {}) => {
  let { callType, ...data } = obj;

  return data;
}

export default function CallTypesModal ({ open, onClose }) {
  const dispatch = useDispatch();

  const callTypes = useSelector(selectCallTypes);
  const filtersApplied = useSelector(selectFiltersApplied);
  const callType = useSelector(selectedCallType);
  const [value, setValue] = useState(callType?._id);

  const options = (callTypes || []).map((item, index) => ({
    key: `callType#${index}`,
    label: item.name,
    value: item._id,
  }));


  const loadData = () => {
    dispatch(loadCallTypes());
  }

  useEffect(() => {
    if (open) {
      loadData();
    }
  }, [open]);

  const selectProps = {
    style: {
      width: "100%",
      maxWidth: "90%",
      marginRight: "5px"
    },
    value,
    options,
    onChange: (newValue) => {
      setValue(newValue);
    },
    maxTagCount: "responsive",
    showArrow: true,
    filterOption: (typed, item) => {
      const contains = String(item.label).toUpperCase().startsWith(String(removeAccents(typed)).toUpperCase())
      return contains;
    },
    allowClear: true
  };

  const handleOpenNew = () => {
    dispatch(openNewModal())
  };

  const handleSaveChanges = () => {
    const payload = {
      callType: value,
      filters: allExceptCallTyoe(filtersApplied)
    };

    dispatch(updateCallType(payload));
    onClose();
  };

  return (
    <Modal
      title={__('callType.update')}
      centered
      open={open}
      onOk={() => handleSaveChanges()}
      okText={__('library.saveChanges')}
      onCancel={onClose}
    >
      <>
        <NewCallTypeModal parentClose={() => onClose()}/>
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
        >
          <div>
          <Select {...selectProps} />
          <Button onClick={() => handleOpenNew()}>+</Button>
            </div>

        </Space>
      </>
    </Modal>
  );
};
