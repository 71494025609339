import { Typography } from "antd";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCC, setCurrentDisplayingMessage } from "./slice";
import styles from "./MediaCC.module.css";
import moment from "moment";
import { getTimestampOfMessageToPlay } from "../../app/utils";

function getSecondsDiff(startDate, endDate) {
  let msInSecond = 1000;

  return Math.round(Math.abs(endDate - startDate) / msInSecond);
}

export default function MediaCC({ meeting, videoRef }) {
  const showCC = useSelector(selectCC);
  const [currentText, setCurrentText] = useState(null);
  const [speaker, setSpeaker] = useState("");
  const dispatch = useDispatch();

  const clearMessages = (meeting.transcription || []).map((t) => {
    const timestampStart = getTimestampOfMessageToPlay(meeting, t);

    return {
      ...t,
      timestampStart,
    };
  });

  const onTimeUpdate = (e) => {
    const currentTime = e.target.currentTime;

    const currentMessages = clearMessages.filter(
      (m) => currentTime >= m.timestampStart
    );
    
    if (currentMessages.length) {
      const lastMessage = currentMessages[currentMessages.length - 1];
      
      dispatch(setCurrentDisplayingMessage(lastMessage));
      setCurrentText(lastMessage.text);  
      setSpeaker(lastMessage.who);  
    }

    // const message = meeting.transcription.find(
    //   (m) =>
    //     e.target.currentTime > dateToVideoTime(m.startAt) - 15 &&
    //     e.target.currentTime < dateToVideoTime(m.endAt)
    // );

    // if (message) {
    //   setSpeaker(message.who);
    //   setCurrentText(message.text);
    // }
  };

  useEffect(() => {
    async function test() {
      videoRef.current.addEventListener("timeupdate", (event) =>
        onTimeUpdate(event)
      );
    }

    test();
  }, []);

  if (!showCC) {
    return;
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Typography.Title level={5} style={{ color: "#fff" }}>
          {speaker}
        </Typography.Title>
        <span>{currentText}</span>
      </div>
    </div>
  );
}
