import { useSelector } from "react-redux";
import LoggedIn from "./LoggedIn";
import LoginForm from "./LoginForm";
import ExtensionLoginForm from "./ExtensionLoginForm";
import { selectIsLoggedIn } from "./slice";
import { useLocation } from "react-router-dom";

const Check = ({ children }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const route = useLocation();

  if (route.pathname === '/extension/login') {
    return <ExtensionLoginForm/>
  }
  if (route.pathname.includes('set-password') || route.pathname.includes('share')) {
    return
  }

  return isLoggedIn ? <LoggedIn>{children}</LoggedIn> : <LoginForm />;
};

export default Check;
