import {
  CalendarTwoTone,
  TrophyTwoTone,
  ScheduleOutlined,
  PlusCircleTwoTone,
  MinusCircleOutlined
} from "@ant-design/icons";
import {
  Avatar,
  Breadcrumb,
  Card,
  Empty,
  Layout,
  Row,
  Tooltip,
  Typography,
} from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  loadMeetingsByLibrary,
  removeMeetingFromLibrary,
  selectLibraryMeetings,
  selectSelectedLibrary,
} from "./slice";
import meetingsStyles from "../meetings/meetings.module.css";
import Meta from "antd/lib/card/Meta";
import MeetingStatusIcon from "../meetings/MeetingStatusIcon";
import { firstUp } from "../meetings/Item";
import moment from "moment";
import { Content } from "antd/lib/layout/layout";
import __ from "../../app/i18n";

const LibraryMeetings = () => {
  const { id } = useParams("id");
  const dispatch = useDispatch();
  const libraryMeetings = useSelector(selectLibraryMeetings);
  const selectedLibrary = useSelector(selectSelectedLibrary);

  useEffect(() => {
    async function load () {
      dispatch(loadMeetingsByLibrary(id));
    }

    load()
  }, []);

  const linkToMeeting = (meetingId, children) => {
    return <Link to={`/meeting/${meetingId}`}>{children}</Link>;
  };

  const handleRemoveFromLibrary = (libraryId, meetingId) => {
    dispatch(removeMeetingFromLibrary({ libraryId, meetingId }))
  };

  return (
    <Content style={{ padding: "0 25px" }}>
      <Breadcrumb style={{ margin: "15px 0" }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/library">{__('library.label')}</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{selectedLibrary?.name}</Breadcrumb.Item>
      </Breadcrumb>
      <Layout
        className={"site-layout-background main-layout-container"}
        style={{ padding: "0" }}
      >
        <Row>
          {/* <LibrariesSidebar /> */}

          {libraryMeetings.map(({ meeting, addedBy, comment }) => {
            const { dateFrom, dateTo, host } = meeting;

            const descriptionContainer = (
              <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
                <li key={`${id}-meeting-description-date`}>
                  <CalendarTwoTone />{" "}
                  <Typography.Text type="secondary">
                    {firstUp(moment(dateFrom).format("dddd").replace("º", "")) +
                      " " +
                      moment(dateFrom).format("Do").replace("º", "") +
                      " de " +
                      moment(dateFrom).format("MMMM") +
                      " del " +
                      moment(dateFrom).format("YYYY")}{" "}
                    ⋅ {moment(dateFrom).format("HH:mm")} -{" "}
                    {moment(dateTo).format("HH:mm")}
                  </Typography.Text>
                </li>

                <li key={`${id}-meeting-description-host`}>
                  <TrophyTwoTone />{" "}
                  <Typography.Title
                    type="secondary"
                    level={5}
                    style={{ display: "inline-block", color: "#198fff" }}
                  >
                    {host}
                  </Typography.Title>
                </li>

                <li key={`${id}-meeting-added-by`}>
                  <PlusCircleTwoTone />{" "}
                  <span>
                    <Typography.Text type="secondary">
                      {__("library.addedBy")} {addedBy}
                    </Typography.Text>
                    {comment ? (
                      <Typography.Text
                        style={{ display: "block", margin: "5px 15px 0" }}
                        keyboard
                      >
                        {comment}
                      </Typography.Text>
                    ) : (
                      ""
                    )}
                  </span>
                </li>
              </ul>
            );

            return (
              <Card className={"libraryCards"} style={{ marginRight: "15px" }}>
                <Tooltip title="Eliminar de la librería">
                  <MinusCircleOutlined onClick={() => handleRemoveFromLibrary(id, meeting.id)} style={{ fontSize: "16px", position: "absolute", right: "15px", cursor: "pointer" }}/>
                </Tooltip>
                {linkToMeeting(
                  meeting.id,
                  <Meta
                    avatar={
                      <div
                        className={
                          meetingsStyles.avatarMeetingDurationContainer
                        }
                      >
                        <Avatar
                          size={75}
                          src={meeting.img}
                          icon={<ScheduleOutlined />}
                        />
                        <MeetingStatusIcon meeting={meeting} />
                      </div>
                    }
                    title={meeting.name}
                    description={descriptionContainer}
                  />
                )}
              </Card>
            );
          })}

          {!libraryMeetings.length ? <Empty className="no-library-meetings"/> : ""}
        </Row>
      </Layout>
    </Content>
  );
};

export default LibraryMeetings;
