import styles from "./styles.module.css";
import { Comment } from "antd";
import moment from "moment";

export default function Message({ comment }) {
  return (
    <Comment
          className={styles.chatComment + " conversation-message-" + String(comment.type).toLowerCase()}
          key={`comment:${comment.id}`}
          dataId={`comment:${comment.id}`}
          author={<a>{comment.name}</a>}
          content={<p className={styles.messageChatContent}>
            {comment.text}
            <span className={styles.messageChatContentTime}>{moment(comment.date).format('HH:mm')}</span>
          </p>}
        />
  );
}
