import { Progress, Typography } from "antd";
import styles from "./index.module.css";
import { Button, Modal } from 'antd';
import { useState } from "react";
import { SamuScoreModal } from "./Modal";
import { useSelector } from "react-redux";
import { selectUser } from "../../session/slice";
import moment from "moment";
import UncompletedScore from "./UncompletedScore";

export const SamuScoreItem = ({ meeting, size }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const user = useSelector(selectUser);

  if (!meeting.samuScore && !meeting.customScore) {
    return
  }

  const p = Math.round((meeting.samuScore || meeting.customScore).total * 10) / 10;
  let color = 'red';
  if (p > 4) {
    color = 'yellow';
  }
  if (p > 6) {
    color = '#52c51a';
  }

  if (!size) {
    size = 70
  }

  let title = "Samu Score";
  if (meeting.customScore) {
    title = (user.accountName || "Samu") + " score";
  }

  if (meeting && meeting.accountId === '65c3b3af3849d3b1577d467c') {
    const diff = moment().diff(moment(meeting.dateTo), 'minute');
    if (diff < 1440 && false) {
      return <UncompletedScore/>
    }
  }

  return (
    <div className={styles.samuScoreItemContainer + ' samu-score-item-container'}>
      <SamuScoreModal open={modalOpen} meeting={meeting} onClose={() => setModalOpen(false)}/>
      <Progress
        className={styles.progressContainer + ' progressContainer'}
        percent={p}
        format={(number) => <span className={styles.percentNumber}>{number}</span>}
        success={{
          percent: p * 10,
          strokeColor: color
        }}
        type="dashboard"
        width={size}
      />

      <Typography className={styles.title}>
        <b className={styles.subtitle}>  {title}</b>
        <a className={styles.subtitle} href="#" title="Ver detalles" onClick={() => setModalOpen(true)}>
          Ver detalles
        </a>
      </Typography>
    </div>
  );
};
