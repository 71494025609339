import {
  PlayCircleOutlined,
  PauseCircleOutlined,
  MessageOutlined,
  FullscreenOutlined,
  ThunderboltOutlined,
  RedoOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./MediaControls.module.css";
import { moveVideo, selectVideoTime, showCC, toggleCC } from "./slice";

export default function DoReDo({ videoRef, type = "back" }) {
  const dispatch = useDispatch();

  const handleClick = () => {
    const currentTime = videoRef.current.currentTime;
    const newTime = type === 'back' ? currentTime - 15 : currentTime + 15;

    dispatch(moveVideo(newTime));
  };

  if (type === "back") {
    return (
      <span style={{ position: "relative" }}> 
        <span className="btn-doredo" style={{ top: '-12px', position: "absolute", left: "24px" }}>15</span>
        <UndoOutlined
        rotate={90}
          style={{ fontSize: "40px", margin: "0 15px" }}
          onClick={handleClick}
        />
      </span>
    );
  } else {
    return (
      <span style={{ position: "relative" }}>
        <span className="btn-doredo" style={{ top: '-12px', position: "absolute", left: "25px" }}>15</span>
        <RedoOutlined
        rotate={-90}
          style={{ fontSize: "40px", margin: "0 15px" }}
          onClick={handleClick}
        />
      </span>
    );
  }
}
