import { Select, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import __ from "../../../../app/i18n";
import { removeAccents } from "../../../../app/utils";
import { selectClients } from "../../../team/slice";
import { selectedClients, updateClientsSelection } from "../slice";

export default function ClientFilter() {
  const clients = useSelector(selectClients);
  const appliedClients = useSelector(selectedClients);
  const dispatch = useDispatch();

  const options = (clients || []).map((item, index) => ({
    key: `client#${index}`,
    label: item.name,
    value: item._id,
  }));

  const handleOnChange = (speakerIds) => {
    dispatch(updateClientsSelection(speakerIds));
  };

  const selectProps = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    options,
    value: appliedClients,
    onChange: (e) => handleOnChange(e),
    placeholder: __('meetings.filters.selectClient'),
    showArrow: true,
    filterOption: (typed, item) => {
      const contains = String(item.label).toUpperCase().startsWith(String(removeAccents(typed)).toUpperCase())
      return contains;
    }
  };

  return (
    <Space
      direction="vertical"
      style={{
        width: "calc(100% - 30px)",
        margin: "15px",
      }}
    >
      <Select {...selectProps}/>
    </Space>
  );
}
