import moment from "moment";
import styles from "./styles.module.css";
import { Comment } from "antd";
import React, { useState, useRef, useEffect } from "react";
import { PlayerPause, PlayerPlay } from "tabler-icons-react";

const AudioMessage = ({ src, date }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(null);

  // Update time as the audio plays
  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  // Toggle play/pause state
  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  // Format time in MM:SS
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  // Set the duration of the audio once it's loaded
  useEffect(() => {
    const setAudioDuration = () => setDuration(audioRef.current.duration);
    audioRef.current.addEventListener("loadedmetadata", setAudioDuration);
    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener("loadedmetadata", setAudioDuration);
      }
    };
  }, []);

  return (
    <div className="audio-message">
      {/* Play/Pause Button */}
      <button className="play-pause-button" onClick={togglePlayPause}>
        {isPlaying ? (
          <PlayerPause style={{ fontSize: "35px" }} />
        ) : (
          <PlayerPlay style={{ fontSize: "35px" }} />
        )}
      </button>

      {/* Time and Seek Bar */}
      <div className="audio-info">
        <span className="current-time">{formatTime(currentTime)}</span>
        <input
          type="range"
          className="seek-bar"
          value={currentTime}
          max={duration}
          onChange={(e) => (audioRef.current.currentTime = e.target.value)}
        />
        <span className="duration">{formatTime(duration)}</span>
      </div>

      {/* Audio Element */}
      <audio
        ref={audioRef}
        src={src}
        onTimeUpdate={handleTimeUpdate}
        onEnded={() => setIsPlaying(false)} // Stop when audio ends
      />

      <span className={styles.messageChatContentTime} style={{ bottom: "-20px" }}>{date}</span>
    </div>
  );
};

export default function Audio({ comment }) {
  return (
    <Comment
      className={
        styles.chatComment +
        " conversation-message-" +
        String(comment.type).toLowerCase()
      }
      key={`comment:${comment.id}`}
      dataId={`comment:${comment.id}`}
      author={<a>{comment.name}</a>}
      content={
        <p className={styles.messageChatContent}>
          {/* <audio src={comment.path} controls/> */}
          <AudioMessage
            src={comment.path}
            date={moment(comment.date).format("HH:mm")}
          />
        </p>
      }
    />
  );
}
