import React, { useEffect, useState } from "react";
import moment from "moment";
import Api from "../../app/api";
import MeetingTimeline from "./MeetingTimeline";
import MeetingTimelineSeconds from "./MeetingTimelineSeconds";

const MeetingTimelineContainer = (props) => {
  if (['GOOGLE', 'MICROSOFT'].includes(props.meeting.provider)) {
    return <MeetingTimeline {...props}/>
  } else {
    return <MeetingTimelineSeconds {...props}/>
  }
};

export default MeetingTimelineContainer;
