import { Space, Alert } from "antd";
import __ from "../../../app/i18n";

const NeedLoginMessage = () => {
  return (
    <Space
      direction="vertical"
      style={{
        marginBottom: 15,
        display: "block",
        maxWidth: "700px",
      }}
    >
      <Alert
        message={
          <div style={{ paddingLeft: "10px" }}>
            {__("generic.alerts.googleAuthNeeded")}. Te recomendamos leer nuestra 
            <a href="https://samu.ai/politica-de-uso-limitado-google/" target="_blank" rel="noreferrer"> Limited Use Policy</a> & {' '}
            <a href="https://samu.ai/privacy/" target="_blank" rel="noreferrer"> Privacy Policy</a>
          </div>
        }
        banner
      />
    </Space>
  );
};
export default NeedLoginMessage;
