import { Card, Form, Input, Select } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";

const placeholders = {
  number: {
    description:
      "Es el tamaño del equipo de ventas mencionado en la transcripción por el cliente.",
    name: "size",
  },
  string: {
    name: "provider",
    description:
      "Es el proveedor que utiliza el cliente para las llamadas mencionado en la transcripción por el cliente. Si no lo encontras, utiliza el valor 'No Mencionado'.",
  },
  enum: {
    name: "CRM",
    description:
      "Es el CRM mencionado en la transcripción utilizado por el cliente.",
    values: ["Hubspot", "Salesforce", "Pipedrive"],
  },
};

function Property(props) {
  const [name, setName] = useState(props.name);
  const [description, setDescription] = useState(props.description);
  const [type, setType] = useState(props.type);
  const [values, setValues] = useState(props.values);

  const [placeHolder, setPlaceHolder] = useState(placeholders["enum"]);
  /**
   * type: number/string/enum
   *
   */
  const types = ["number", "string", "enum"];

  useEffect(() => {
    props.onUpdateProperty({ index: props.index, type, name, description, values });
  }, [type, name, description, values]);

  return (
    <Card
      size="small"
      title={name.toUpperCase()}
      key={props.index}
      style={{ marginBottom: "15px" }}
      extra={<CloseOutlined onClick={() => props.onRemove(props.index)} />}
      headStyle={{ background: "#eee" }}
    >
      <Form layout="vertical">
        <Form.Item label="Tipo de propiedad" required>
          <Select
            style={{ width: "150px" }}
            value={type}
            onChange={(e) => {
              setType(e);
              if (e !== "enum") {
                setValues();
              }
            }}
          >
            {types.map((t) => (
              <Select.Option key={t}>{t.toUpperCase()}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Nombre de la propiedad" required>
          <Input
            key={`name`}
            value={name}
            placeholder={placeHolder.name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          label="Descripción de la propiedad (muy importante que brindes el mayor detalle posible)"
          required
        >
          <Input.TextArea
            key={[props.index, 'desc'].join("#")}
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            style={{ display: "block", width: "100%" }}
            placeholder={placeHolder.description}
          />
        </Form.Item>

        {type === "enum" ? (
          <Form.Item label="Posibles valores (separados por coma)" required>
            <Input.TextArea
              onChange={(e) => setValues(e.target.value.split(','))}
              value={values}
              style={{ display: "block", width: "100%" }}
              placeholder={placeHolder.values.join(",")}
            />
          </Form.Item>
        ) : (
          ""
        )}
      </Form>
    </Card>
  );
}

export default Property;
