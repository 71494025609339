import React, { useEffect, useState } from "react";
import Api from "../../../app/api";
import { CheckCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button } from "antd";
import NeedLoginMessage from "./NeedLoginMessage";
import CanNotRecord from "./CanNotRecord";
import { useSelector } from "react-redux";
import { selectCanRecord } from "../../session/slice";
import __ from "../../../app/i18n";
import googleCalendarLogo from "./assets/googleCalendarLogo.png";
import TrialExpiredMessage from "./TrialExpiredMessage";

const GoogleTab = () => {
  const [ready, setReady] = useState(false);
  const [googleData, setGoogleData] = useState({});
  const canRecord = useSelector(selectCanRecord);

  const laodLoginData = () => {
    Api("getGoogleLoginData").then((data) => {
      setGoogleData(data);
      setReady(true);
    });
  };

  useEffect(() => {
    if (canRecord) {
      laodLoginData();
    }
  }, []);

  const handleLogout = () => {
    setReady(false);
    Api("disconnectGoogle", {}).then(() => {
      laodLoginData();
    });
  };

  if (!canRecord) {
    return <CanNotRecord />;
  }

  if (!ready) {
    return <LoadingOutlined spin />;
  }

  if (ready && googleData.isLoggedIn) {
    return (
      <div>
        <img
          src={googleCalendarLogo}
          alt="Google Calendar"
          style={{ marginRight: "5px" }}
          width={25}
        />
        <CheckCircleOutlined
          style={{
            fontSize: "25px",
            color: "green",
            verticalAlign: "middle",
            display: "inline-block",
          }}
        />
        <Button style={{ marginLeft: "15px" }} onClick={() => handleLogout()}>
          {__("generic.buttons.disconnectGoogle")}
        </Button>
      </div>
    );
  } else if (!googleData.canDoLogin) {
    return (
      <>
        <TrialExpiredMessage />
      </>
    );
  } else {
    return (
      <>
        <NeedLoginMessage />
        <Button
          style={{ padding: "5px 10px", height: "auto" }}
          icon={
            <img
              src={googleCalendarLogo}
              alt="Google Calendar"
              style={{ marginRight: "5px" }}
              width={25}
            />
          }
          onClick={() => (document.location.href = googleData.url)}
        >
          <span>
            Sincronizar con <b> Google Calendar</b>
          </span>
        </Button>
      </>
    );
  }
};

export default GoogleTab;
