import {
  Badge,
  Breadcrumb,
  Card,
  Layout,
  Pagination,
  Row,
  Tooltip,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadApps,
  selectApps,
  selectIsFetching,
  selectFetchedApps,
} from "./slice";

import Loading from "../loading/Index";
import AppTypeItem from "./AppTypeItem";
import __ from "../../app/i18n";
// import GoogleMeet from "./GoogleMeet";
// import HubspotCalling from "./HubspotCalling";

const Index = () => {
  const apps = useSelector(selectApps);
  const dispatch = useDispatch();
  const isFetching = useSelector(selectIsFetching);
  const fetched = useSelector(selectFetchedApps);

  useEffect(() => {
    dispatch(loadApps());
  }, [dispatch, fetched]);

  if (isFetching) {
    return <Loading />;
  }

  return (
    <Content style={{ padding: "0 25px" }}>
      <Layout
        className={"site-layout-background main-layout-container"}
        style={{ padding: "24px 0" }}
      >
        <Content style={{ padding: "0 24px", minHeight: 280 }}>
          <Breadcrumb style={{ margin: "15px 0" }}>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>
              <a href="/apps">{__("apps.label")}</a>
            </Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            {/* Google */}
            {/* <GoogleMeet/>
            <HubspotCalling/> */}
            {!isFetching
              ? (apps || []).map((app) => <AppTypeItem app={app} />)
              : ""}
          </Row>
          <br />
          <Pagination
            hideOnSinglePage
            onChange={() => {}}
            defaultCurrent={1}
            pageSize={20}
            total={apps.length}
          />
        </Content>
      </Layout>
    </Content>
  );
};

export default Index;
