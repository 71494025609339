import { Form, Input, Modal } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import __ from "../../app/i18n";
import { createLibrary } from "./slice";

const NewLibraryModal = ({ open, onClose }) => {
  const [libraryName, setLibraryName] = useState("");
  const dispatch = useDispatch();

  const handleCreation = () => {
    if (libraryName) {
      dispatch(createLibrary({ name: libraryName }))
      onClose()
    }
  };

  return (
    <Modal
      title={__("library.new")}
      okText={__("library.create")}
      centered
      open={open}
      onOk={() => handleCreation()}
      onCancel={onClose}
    >
      <Form.Item
        label={__("library.libraryName")}
        required
        tooltip={__("generic.required.library")}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input required value={libraryName} onChange={(e) => setLibraryName(e.target.value)} placeholder={__("library.newPlaceHolder")} />
      </Form.Item>
    </Modal>
  );
};

export default NewLibraryModal;
