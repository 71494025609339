import AnalyticBar from "./AnalyticBar";
import { Typography } from "antd";
import AnalyticsTabContent from "./AnalyticsTabContent";
import PeriodGraphs from './PeriodGraphs';

export default function QuestionRateTab({ analyticsState }) {
  return (
    <AnalyticsTabContent>
      <Typography.Title className={"analyticsTabTitle"} level={3} style={{ marginBottom: '15px' }}>Cantidad de preguntas por reunión</Typography.Title>
      <AnalyticBar userAnalytics={analyticsState.questionRate.questionRates} prop="avg" avg={analyticsState.questionRate.avg} itemKeyName={'Promedio del usuario'}/>
      <PeriodGraphs title={'Evolución de la cantidad de preguntas por reunión'} userAnalytics={analyticsState.questionRate.periods}/>
    </AnalyticsTabContent>
  );
}
