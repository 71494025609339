import React, { useEffect, useState } from "react";
import Api from "../../../app/api";
import { CheckCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button } from "antd";
import CanNotRecord from "./CanNotRecord";
import { useSelector } from "react-redux";
import { selectCanRecord } from "../../session/slice";
import __ from "../../../app/i18n";
import microsoftLogo from "./assets/microsoftLogo.png";
import TrialExpiredMessage from "./TrialExpiredMessage";

const MicrosoftTab = () => {
  const [ready, setReady] = useState(false);
  const [microsoftData, setMicrosoftData] = useState({});
  const canRecord = useSelector(selectCanRecord);

  const laodLoginData = () => {
    Api("getMicrosoftLoginData").then((data) => {
      setMicrosoftData(data);
      setReady(true);
    });
  };

  useEffect(() => {
    if (canRecord) {
      laodLoginData();
    }
  }, []);

  const handleLogout = () => {
    setReady(false);
    Api("disconnectMicrosoft", {}).then(() => {
      laodLoginData();
    });
  };

  if (!canRecord) {
    return <CanNotRecord />;
  }

  if (!ready) {
    return <LoadingOutlined spin />;
  }

  if (ready && microsoftData.isLoggedIn) {
    return (
      <div>
          <img
            src={microsoftLogo}
            alt="Microsoft Calendar"
            style={{ marginRight: "5px" }}
            width={65}
          />


        <CheckCircleOutlined
          style={{
            fontSize: "25px",
            color: "green",
            verticalAlign: "middle",
            display: "inline-block",
          }}
        />
        <Button style={{ marginLeft: "15px" }} onClick={() => handleLogout()}>
          {__("generic.buttons.disconnectMicrosoft")}
        </Button>
      </div>
    );
  } else if (!microsoftData.canDoLogin) {
    return (
      <>
        <TrialExpiredMessage />
      </>
    );
  } else {
    return (
      <>
        <Button
          style={{ padding: "5px 10px", height: "auto" }}
          onClick={() => (document.location.href = microsoftData.url)}
        >
          <span>
            Sincronizar con{" "}
            <img
              src={microsoftLogo}
              alt="Microsoft Calendar"
              style={{ marginRight: "5px" }}
              width={65}
            />
          </span>
        </Button>
      </>
    );
  }
};

export default MicrosoftTab;
