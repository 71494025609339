import { useSelector } from "react-redux";
import React, {  } from "react";
import { Typography, Badge } from "antd";
import {
  selectLastVisits,
} from "../../slice";
import __ from "../../../../app/i18n";
import moment from "moment";
import Section from "../Section";

const Visits = () => {
  const lastVisits = useSelector(selectLastVisits);

  return (
    <Section
      title={__("meetings.details.sections.lastVisits")}
      sectionKey={"visits"}
      position={'team'}
    >
      {lastVisits.map((visit) => {
        return (
          <div>
            <Badge
              key={`bagde_${visit.id}_index_guests`}
              color="#000"
              text={
                <span>
                  <Typography.Title
                    level={5}
                    style={{ display: "inline-block" }}
                  >
                    {visit.name}
                  </Typography.Title>
                  {" -  "}
                  <Typography.Text
                    type="secondary"
                    style={{ display: "inline-block" }}
                  >
                    {moment(visit.lastDate).format("DD/MM/YYYY")}
                  </Typography.Text>
                </span>
              }
            />
            <br />
          </div>
        );
      })}
    </Section>
  );
};

export default Visits;
