import { Button, Checkbox, Form, Select, Switch, Typography } from "antd";
import _ from "lodash";
import { useState } from "react";
import AppItem from "../AppItem";

function NotLoggedInForm(props) {
  return (
    <AppItem app={props.app} canSave={false} canToggleStatus={false}>
      <Button>
        <a href={props.app.loginUrl}>Conectar</a>
      </Button>
    </AppItem>
  );
}

function App(props) {
  const [enabled, setEnabled] = useState(props.app.enabled);
  const [pipelines, setPipelines] = useState(props.app.selectedPipelines || []);
  const [logMeetingEnabled, setLogMeetingEnabled] = useState(
    props.app.actions.logMeeting
  );
  const [logMeetingAsNoteEnabled, setLogMeetingAsNoteEnabled] = useState(
    props.app.actions.logMeetingAsNote
  );
  const [createActionItemsAsTasksEnabled, setCreateActionItemsAsTasksEnabled] = useState(
    props.app.actions.createActionItemsAsTasks
  );

  if (!props.app.isLoggedIn) {
    return <NotLoggedInForm {...props} />;
  }

  const handleSave = () => {
    const newSettings = {
      ...props.app,
      enabled,
      pipelines,
      actions: {
        ...props.app.actions,
        logMeeting: logMeetingEnabled,
        logMeetingAsNote: logMeetingAsNoteEnabled,
        createActionItemsAsTasks: createActionItemsAsTasksEnabled,
      },
    };

    props.handleOnSave(newSettings);
  };

  return (
    <AppItem
      app={props.app}
      onToggleStatus={(e) => setEnabled(e)}
      canSave={props.app.isLoggedIn}
      onSave={handleSave}
      canToggleStatus={props.app.isLoggedIn}
      enabled={enabled}
    >
      <Form>
        <Typography.Title level={5}>Configuración</Typography.Title>

        <Form.Item label="Pipelines" style={{ marginTop: "15px" }} required>
          <Select
            mode="multiple"
            value={pipelines}
            onChange={(v) => setPipelines(v)}
          >
            {(props.app.pipelines || []).map(({ id, label }) => (
              <Select.Option key={id}>{label.toUpperCase()}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Typography.Title level={5}>Automatizaciones</Typography.Title>
        <div>
          <Checkbox
            checked={logMeetingEnabled}
            onChange={(e) => setLogMeetingEnabled(e.target.checked)}
          >
            Registrar reunión desde Samu en HubSpot como "Meeting"
          </Checkbox>
        </div>

        <div>
          <Checkbox
            checked={logMeetingAsNoteEnabled}
            onChange={(e) => setLogMeetingAsNoteEnabled(e.target.checked)}
          >
            Registrar reunión desde Samu en HubSpot como "Nota"
          </Checkbox>
        </div>

        <div>
          <Checkbox
            checked={createActionItemsAsTasksEnabled}
            onChange={(e) => setCreateActionItemsAsTasksEnabled(e.target.checked)}
          >
            Registrar compromisos detectados como tareas en HubSpot
          </Checkbox>
        </div>
      </Form>
    </AppItem>
  );
}

export default App;
