import { Layout, Menu, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import styles from "./loading.module.css";


export default function Loading({ fontSize }) {
  if (!fontSize) {
    fontSize = 100;
  }

  const antIcon = <LoadingOutlined style={{ fontSize }} spin />;

  return (
    <div className={styles.loadingContainer}>
      <div className={styles.loading} style={{ textAlign: "center" }}>
        <Spin indicator={antIcon} />
      </div>
    </div>
  );
}
