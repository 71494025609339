import { Tooltip, Typography } from "antd";

function getProbability(meeting) {
  return {
    value: meeting?.extractor?.samu_probKey,
    description: meeting?.extractor?.samu_probDesc,
  };
}

function MeetingProbabilityDescription({ value, description, style }) {
  const content = (
    <div style={{ textAlign: "center" }}>
      <Typography.Text
        style={{
          display: "block",
          background: "#b389ff",
          marginTop: "10px",
          borderRadius: "5px",
          color: "#fff",
          fontSize: "12px",
        }}
        type="secondary"
      >
        Probabilidad de venta
      </Typography.Text>
      <span style={{ color: "orange" }}>
        {"★".repeat(value) + "☆".repeat(5 - value)}
      </span>
    </div>
  );

  if (description && description.length > 10) {
    return <Tooltip title={description}>{content}</Tooltip>;
  } else {
    return content;
  }
}

export default function MeetingProbabilityItem({ meeting, value, description }) {
  let probability = { description, value };
  if (!(value || description)) {
    probability = getProbability(meeting);
  }

  if (probability && (probability.value || probability.description)) {
    return (
      <MeetingProbabilityDescription
        value={probability.value}
        description={probability.description}
      />
    );
  }
}
