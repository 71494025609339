import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../../../../app/api';

const initialState = {
  status: 'iddle',
  progress: 0,
  summaryStatus: '',
  reports: {
    sales: {},
    spiced: {},
    summary: {}
  },
  errorCode: null
};

export const meetingSlice = createSlice({
  name: 'ai',
  initialState,
  reducers: {
    clearErrors (state) {
      state.errorCode = null;
      state.status = 'idle'
    }
  },

  extraReducers: (builder) => {
  },
});

export const { clearErrors } = meetingSlice.actions;
export const selectSummaryProgress = (state) => state.ai.progress;
export const selectSummaryInProgress = (state) => state.ai.summaryStatus === 'in_progress';
export const selectShouldShowButton = (state) => !['in_progress', 'done'].includes(state.ai.summaryStatus);
export const selectSalesContent = (state) => state.ai.reports.sales;
export const selectSummaryContent = (state) => state.ai.reports.summary
export const selectSpicedContent = (state) => state.ai.reports.spiced;
export const selectError = (state) => state.ai.status === 'error' ? state.ai.errorCode : '';

export default meetingSlice.reducer;
