import { Button, Modal, Select, Space, Spin } from "antd";
import { useEffect, useState } from "react";
import {
  loadLibraries,
  loadMeetingLibraries,
  selectFetchedLibraryMeetings,
  selectIsFetching,
  selectLibraryMeetings,
  selectMeetingLibraries,
  updateLibrariesToMeeting,
  selectSaved
} from "./slice";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../app/api";
import { startLoading } from "../loading/slice";
import Loading from "../loading/Index";
import { LoadingOutlined } from "@ant-design/icons";
import __ from "../../app/i18n";
import TextArea from "antd/lib/input/TextArea";
import NewLibraryModal from "./NewLibraryModal";
import { removeAccents } from "../../app/utils";

// - Create new Library
// Use Existing ones
// If meeting ID => List existing libraries for meeting ID.

const LibraryModal = ({ meeting, open, onClose }) => {
  const [newLibraryIsOpen, setNewLibraryIsOpen] = useState(false);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const meetingLibraries = useSelector(selectMeetingLibraries);
  const saved = useSelector(selectSaved);
  const isFetching = useSelector(selectIsFetching);
  const [value, setValue] = useState([]);
  const [options, setOptions] = useState([]);
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (open) {
      loadData();
    }
  }, [dispatch, meeting, open]);

  const loadData = () => {
    setLoading(true);
    dispatch(loadLibraries());
    dispatch(loadMeetingLibraries(meeting.id));
  }

  useEffect(() => {
    if (Array.isArray(meetingLibraries)) {
      setLoading(false);

      const inUse = [];

      const newOptions = [];
      meetingLibraries.forEach((libMeeting) => {
        newOptions.push({
          value: libMeeting._id,
          label: libMeeting.name,
        });

        if (libMeeting.inUse) {
          inUse.push(libMeeting._id);
        }
      });

      setValue(inUse);
      setOptions(newOptions);
    }
  }, [meetingLibraries]);

  const selectProps = {
    mode: "multiple",
    style: {
      width: "100%",
      maxWidth: "90%",
      marginRight: "5px"
    },
    value,
    options,
    onChange: (newValue) => {
      setValue(newValue);
    },
    maxTagCount: "responsive",
    showArrow: true,
    filterOption: (typed, item) => {
      const contains = String(item.label).toUpperCase().startsWith(String(removeAccents(typed)).toUpperCase())
      return contains;
    }
  };

  useEffect(() => {
    if (saved) {
      onClose();
    }
  }, [saved])


  const antIcon = <LoadingOutlined style={{ fontSize: 100 }} spin />;

  const onChangeComment = (e) => {
    setComment(e.target.value);
  }

  const handleSaveChanges = () => {
    const payload = {
      meetingId: meeting.id,
      libraries: value,
      comment
    };

    dispatch(updateLibrariesToMeeting(payload));
  };

  if (isLoading || isFetching) {
    return (
      <Modal
      title={__('library.addToLibrary')}
        centered
        open={open}
        onOk={() => onClose()}
      >
        <div style={{ textAlign: "center" }}>
          <Spin indicator={antIcon} />
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      title={__('library.addToLibrary')}
      centered
      open={open}
      onOk={() => handleSaveChanges()}
      okText={__('library.saveChanges')}
      onCancel={onClose}
    >
      <>
        <NewLibraryModal open={newLibraryIsOpen} onClose={() => { setNewLibraryIsOpen(false); loadData() }}/>
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
        >
          <div>
          <Select {...selectProps} />
          <Button onClick={() => setNewLibraryIsOpen(true)}>+</Button>
            </div>
          <br/>
          <span>{__('library.comments')}</span>
          <TextArea placeholder={__('library.comments')} allowClear onChange={onChangeComment} />

        </Space>
      </>
    </Modal>
  );
};

export default LibraryModal;
