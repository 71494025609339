import { useSelector } from "react-redux";
import { selectMeeting } from "../../../slice";
import { Button, Comment, Input } from "antd";
import { useEffect, useState } from "react";
import Api from "../../../../../app/api";
import { selectUser } from "../../../../session/slice";
import { analytics } from "../../../../../hooks/tracker";
import ChatDisclaimer from "./ChatDisclaimer";

const ChatTab = () => {
  const [message, setMessage] = useState("");
  const user = useSelector(selectUser);
  const [loading, setLoading] = useState(false);
  const meeting = useSelector(selectMeeting);
  const [comments, setComments] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const chats = await Api("getAiChats", {
        id: meeting.id,
        reportType: "chat",
      });

      const history = chats.reduce((prev, curr, id) => {
        prev.push({ id, type: 'USER', who: user.name, content: curr.question });
        prev.push({ id, type: 'APP', who: 'Samu Chat Agent', content: curr.answer });

        return prev;
      }, []);

      setComments(history);
    };

    fetch();
  }, []);

  const handleMessage = async() => {
    setComments(comments => [...comments, { id: comments.length + 1, type: 'USER', who: user.name, content: message, createdAt: new Date() }]);
    setLoading(true);
    setMessage('');

    Api('chatMeeting', { id: meeting.id, message }).then(({ content }) => {
      setComments(comments => [...comments, { id: comments.length + 1 + (new Date().getTime()), type: 'APP', who: "Samu Chat Agent", content: content.answer, createdAt: new Date() }]);
      setLoading(false);

      if (analytics) {
        analytics.track("Meeting Chat", {
          id: meeting.id,
          question: content.question,
          answer: content.answer,
        });
      }
    });
  };

  return (
    <>
      <ChatDisclaimer />
      {comments.map((comment) => (
        <Comment
          className={'ai-chat-message-' + String(comment.type).toLowerCase()}
          key={`comment:${comment.id}`}
          dataId={`comment:${comment.id}`}
          author={<a>{comment.who}</a>}
          content={<p>{comment.content}</p>}
        />
      ))}

      <Input.TextArea
        rows={2}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <Button loading={loading} type="primary" style={{ width: "100%", marginTop: "15px" }} onClick={handleMessage}>
        Chat
      </Button>
    </>
  );
};

export default ChatTab;
