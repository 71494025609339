import CompareButton from "../Compare/CompareButton";
import CallTypeFilter from "./CallTypeFilter";
import DateFilter from "./DateFilter";
import UserFilter from "./UserFilter";

export default function AnalyticsFilter ({ isSecondary, isComparing } = { isSecondary: false }) {
  return <>
    <CallTypeFilter isSecondary={isSecondary}/>
    <DateFilter isSecondary={isSecondary}/>
    <UserFilter isSecondary={isSecondary}/>
    { !isSecondary && !isComparing? <CompareButton/> : ''}
    { isSecondary && isComparing? <CompareButton/> : ''}
  </>
}