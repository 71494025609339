import { Select, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectedExcludedTags, selectedTags, updateTagsExcludeSelection, updateTagsSelection } from "../slice";
import __ from "../../../../app/i18n";
import { removeAccents } from "../../../../app/utils";
import { loadTags, selectTags } from "../../../tags/slice";

export default function TagFilter() {
  const appliedTags = useSelector(selectedTags);
  const tags = useSelector(selectTags);
  const [includedTags, setIncludedTags] = useState(appliedTags);
  const [excludedTags, setExcludedTags] = useState(useSelector(selectedExcludedTags));
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadTags());
  }, []);

  useEffect(() => {
    const newOptions = (tags || []).map(item => ({ label: item.name, value: item._id }));
    
    setOptions(newOptions);
  }, [tags]);

  useEffect(() => {
    setIncludedTags(appliedTags)
  }, [appliedTags]);

  const handleTagsChange = (values) => {
    setIncludedTags(values);
    dispatch(updateTagsSelection(values));
  };

  const handleExcludeTagsChange = (values) => {
    setExcludedTags(values);
    dispatch(updateTagsExcludeSelection(values));
  };

  const selectProps = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    options,
    placeholder: __('tag.select'),
    maxTagCount: "responsive",
    showArrow: true,
    filterOption: (typed, item) => {
      const contains = String(item.label).toUpperCase().startsWith(String(removeAccents(typed)).toUpperCase())
      return contains;
    }
  };

  return (
    <>

      <Space
        direction="vertical"
        style={{
          width: "calc(100% - 30px)",
          margin: "15px",
        }}
      >
        <Typography.Text style={{ marginBottom: "5px", marginTop: "10px", display: "block" }} type="secondary">{__('tag.include')}</Typography.Text>
        <Select key={'include-tags-filter'} {...selectProps} onChange={(tags) => handleTagsChange(tags)} value={includedTags}/>
        <br/>

        <Typography.Text style={{ marginBottom: "5px", marginTop: "10px", display: "block" }} type="secondary">{__('tag.exclude')}</Typography.Text>
        <Select key={'exclude-tags-filter'} {...selectProps} onChange={(tags) => handleExcludeTagsChange(tags)} value={excludedTags}/>
      </Space>
    </>
  );
}
