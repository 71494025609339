import { Select, Space } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectedLibraries, updateLibrariesSelection } from "../slice";
import { loadLibraries, selectLibraries } from '../../../library/slice';
import __ from "../../../../app/i18n";
import { removeAccents } from "../../../../app/utils";

export default function LibraryFilter() {
  const appliedLibraries = useSelector(selectedLibraries);
  const libraries = useSelector(selectLibraries);
  const [value, setValue] = useState(appliedLibraries);
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadLibraries());
  }, []);

  useEffect(() => {
    const newOptions = libraries.map(item => ({ label: item.name, value: item.id }));
    
    setOptions(newOptions);
  }, [libraries]);

  useEffect(() => {
    setValue(appliedLibraries)
  }, [appliedLibraries]);

  const handleChange = (values) => {
    setValue(values);
    dispatch(updateLibrariesSelection(values));
  };

  const selectProps = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    value,
    options,
    onChange: (newValue) => {
      handleChange(newValue);
    },
    placeholder: __('library.selectLibrary'),
    maxTagCount: "responsive",
    showArrow: true,
    filterOption: (typed, item) => {
      const contains = String(item.label).toUpperCase().startsWith(String(removeAccents(typed)).toUpperCase())
      return contains;
    }
  };

  return (
    <>
      <Space
        direction="vertical"
        style={{
          width: "calc(100% - 30px)",
          margin: "15px",
        }}
      >
        <Select {...selectProps} />
      </Space>
    </>
  );
}
