import { Button, Checkbox, Input, Space, Typography } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import { FileWordFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { selectedWords, updateAndSearch, updateFilters } from "../slice";
import { useEffect, useState } from "react";
import __ from "../../../../app/i18n";
const { Text } = Typography;

export default function WordsFilter() {
  const dispatch = useDispatch();
  const stateWords = useSelector(selectedWords);
  const [currentWords, setWords] = useState(stateWords);
  const [speakerTypes, setSpeakerTypes] = useState({
    seller: null,
    client: null,
  });

  useEffect(() => {
    setWords(stateWords)

    if (!stateWords) {
      setSpeakerTypes({ seller: null, client: null })
    }
  }, [stateWords]);

  const handleClick = () => {
    dispatch(updateAndSearch({ words: currentWords, speakerTypes }));
  };

  const handleSpeakerTypeChange = (change) => {
    setSpeakerTypes({ ...speakerTypes, ...change });
  };

  return (
    <div style={{ padding: "10px" }}>
      <Input onChange={(e) => setWords(e.target.value)} value={currentWords} />
      <Space style={{ margin: "10px 5px" }}>
        <Text style={{ display: "block" }}>
          {__("meetings.filters.mentionedBy")}
        </Text>
        <Checkbox
          checked={speakerTypes.seller}
          onChange={(e) =>
            handleSpeakerTypeChange({ seller: e.target.checked })
          }
        >
          {__("generic.fields.seller")}
        </Checkbox>
        <Checkbox
          checked={speakerTypes.client}
          onChange={(e) =>
            handleSpeakerTypeChange({ client: e.target.checked })
          }
        >
          {__("meetings.filters.client")}
        </Checkbox>
      </Space>
      <Button
        type="primary"
        style={{ marginTop: "5px", width: "100%" }}
        onClick={handleClick}
      >
        {__("meetings.filters.apply")}
      </Button>
    </div>
  );
}
