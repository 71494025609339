import { useSelector } from "react-redux";
import React, { useState } from "react";
import { Input } from "antd";
import { selectMeeting } from "../../slice";
import __ from "../../../../app/i18n";
import TranscriptionList from "./TranscriptionList";
import Section from "../Section";

const SearchList = () => {
  const meeting = useSelector(selectMeeting);
  const [q, setQ] = useState("");

  return (
    <Section
      title={__("meetings.details.sections.search")}
      sectionKey={"search"}
    >
      <div>
        <Input.Search
          placeholder={__("meetings.details.sections.search")}
          onSearch={(e) => setQ(e)}
          style={{}}
        />
        <TranscriptionList meeting={meeting} q={q} onClear={() => setQ('')}/>
      </div>
    </Section>
  );
};

export default SearchList;
