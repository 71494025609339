import { Button, Layout, Menu } from "antd";
import { Footer, Header } from "antd/lib/layout/layout";
import styles from "../session/LoggedIn.module.css";
import __ from "../../app/i18n";
import logo from "../../brand/Isologotype/samu_white_dark_bckgrnd.png";

function ShareLayout({ children, openDemoModal }) {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header className="header">
        <Menu
          theme="light"
          mode="horizontal"
          defaultSelectedKeys={[]}
          triggerSubMenuAction="click"
        >
          <Menu.Item
            key="logo"
            style={{ fontFamily: "secular" }}
            className={styles.logoMenuItem}
          >
            <img src={logo} alt={__("appName")} style={{ width: "135px" }} />
          </Menu.Item>
          <Menu.Item
            key="demo"
            style={{ fontFamily: "secular", position: "absolute", right: 0 }}
          >
            <Button onClick={() => openDemoModal()}>Quiero saber mas de Samu!</Button>
          </Menu.Item>
        </Menu>
      </Header>
      {children}
      <Footer
        style={{
          textAlign: "center",
          background: "#1791ff",
          color: "#fff",
          borderTop: "1px solid #ddd",
        }}
      >
        {__("appName")} © {new Date().getFullYear()}
      </Footer>
    </Layout>
  );
}

export default ShareLayout;
