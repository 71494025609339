import { useState } from "react";
import AppItem from "../AppItem";

function App(props) {
  const [enabled, setEnabled] = useState(
    props.app.enabled
  );

  const handleSave = () => {
    const newSettings = {
      ...props.app,
      enabled,
    };

    props.handleOnSave(newSettings);
  };

  return (
    <AppItem app={props.app} onToggleStatus={e => setEnabled(e)} canSave={props.app} onSave={handleSave} canToggleStatus={props.app} enabled={enabled} />
  );
}

export default App;
