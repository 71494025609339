import { Tag } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { selectTrackers } from "../../../team/slice";
import { selectedTrackers, toggleTrackersSelection } from "../slice";
import styles from "./HostsFilter.module.css";
const { CheckableTag } = Tag;

export default function TrackerFilter() {
  const appliedTrackers = useSelector(selectedTrackers);
  const trackers = useSelector(selectTrackers);
  const dispatch = useDispatch();

  const isSelected = (trackerId) => {
    return appliedTrackers.includes(trackerId);
  };

  const handleChange = (trackerId) => {
    dispatch(toggleTrackersSelection(trackerId));
  };

  return (
    <>
      {(trackers || []).map((tracker, index) => {
        return (
          <li key={'li_' + tracker._id + '_' + index} style={{ margin: "5px" }}>
            <CheckableTag
              className={styles.hostItem}
              key={'tracker_' + tracker._id + '_' + index}
              checked={isSelected(tracker._id)}
              onChange={(checked) => handleChange(tracker._id, checked)}
            >
              {tracker.name}
            </CheckableTag>
          </li>
        );
      })}
    </>
  );
}
