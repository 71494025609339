import Modal from "antd/lib/modal/Modal";
import { useEffect, useState } from "react";
import Api from "../../app/api";
import { Alert, Input, Typography, message } from "antd";
import { copyTextToClipboard } from "../../app/utils";
import { CopyOutlined } from "@ant-design/icons";
import moment from "moment";

const ShareMeetingModal = ({ open, onClose, meeting }) => {
  const [shareId, setShareId] = useState();
  const [dueDateStr, setDueDateStr] = useState();
  const [messageApi, contextHolder] = message.useMessage();

  const handleCopy = () => {
    copyTextToClipboard(shareId);

    messageApi.open({
      type: "success",
      content: "Copiado al portapapeles",
    });
  };

  useEffect(() => {
    async function fetch() {
      const { status, id, dueDate } = await Api("getShareableUrl", { id: meeting.id });
      if (status !== "ERROR") {
        const url = "https://web.samu.ai/share/" + id;

        copyTextToClipboard(url);
        setShareId(url);
        setDueDateStr(moment(dueDate).format('DD/MM/YYYY HH:mm'))
      }
    }

    if (open) {
      fetch();
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      onOk={() => {
        handleCopy();
        onClose();
      }}
      closable={false}
      cancelButtonProps={{ show: false }}
      okText="Copiar Link"
      cancelText="Cerrar"
      title="Compartir"
    >
      {contextHolder}
      <Alert message={<>El siguiente link es publico y tiene una validez de 7 dias. Es accesible hasta el <b>{dueDateStr + 'hs'}</b></>}/>
      <Input
      style={{ marginTop: "10px" }}
        value={shareId}
        addonAfter={
          <CopyOutlined
            style={{
              fontSize: "18px",
              cursor: "pointer",
            }}
            onClick={handleCopy}
          />
        }
      />
    </Modal>
  );
};

export default ShareMeetingModal;
