import { Drawer, Button, Form, Input, Checkbox } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import __ from "../../app/i18n";
import { createUser } from "./slice";

export default function NewTeamUser({ open, onClose }) {
  const dispatch = useDispatch();
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(false);

  const handleNewUser = (values) => {
    dispatch(createUser({ ...values, sendWelcomeEmail }));
  };

  return (
    <Drawer
      title={__("team.list.newUser")}
      placement={"right"}
      width={500}
      onClose={() => onClose()}
      open={open}
      getContainer={true}
    >
      <Form
        name="basic"
        layout="vertical"
        onFinish={handleNewUser}
        autoComplete="off"
      >
        <Form.Item
          name="name"
          label={__("generic.fields.name")}
          rules={[
            {
              required: true,
              message: __("generic.required.name"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="lastName"
          label={__("generic.fields.lastName")}
          rules={[
            {
              required: true,
              message: __("generic.required.lastName"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            {
              required: true,
              message: __("generic.required.email"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={__("generic.fields.sendWelcomeEmail")}
          name="sendWelcomeEmail"
        >
          <Checkbox name="sendWelcomeEmail" onChange={(e) => setSendWelcomeEmail(e.target.checked)}/>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {__("generic.fields.createUser")}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
