import {
  Divider,
  Drawer,
} from "antd";
import __ from "../../app/i18n";
import UserMentioned from "./types/UserMentioned";
import styles from './notification.module.css';
import NewMeeting from "./types/NewMeeting";

export default function NotificationPanel({ open, onClose, notifications }) {
  const notificationRenderer = (notification) => {
    const types = {
      'USER_MENTIONED': <UserMentioned notification={notification} onClose={onClose} />,
      'NEW_MEETING': <NewMeeting notification={notification} onClose={onClose} />
    };
  
    return types[notification.type];
  };

  return (
    <Drawer
      title={__("notifications.label")}
      placement={"left"}
      width={500}
      onClose={onClose}
      open={open}
      getContainer={true}
    >
      {notifications.map((notification) => {
        return (
          <div className={styles.notificationContainer} key={`container::notification::${notification.id || notification._id}`}>
            { notificationRenderer(notification) }
            <Divider />
          </div>
        );
      })}
    </Drawer>
  );
}
