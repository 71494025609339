import { DatePicker } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { filterByDate, selectedDates } from "../slice";
import moment from "moment";

const { RangePicker } = DatePicker;

export default function HostsFilter() {
  const dispatch = useDispatch();
  
  let dates = useSelector(selectedDates);
  if (dates && dates.length) {
    if (dates[0]) {
      dates[0] = moment(dates[0]);
    }

    if (dates[1]) {
      dates[1] = moment(dates[1]);
    }
  }

  const handleChange = (dates) => {
    if (dates) {
      const dateFrom = dates[0] ? dates[0].format("YYYY-MM-DD") : null;
      const dateTo = dates[1] ? dates[1].format("YYYY-MM-DD") : null;

      dispatch(filterByDate({ dateFrom, dateTo }));
    }
  };

  return (
    <div style={{ padding: "15px", zIndex: 9999 }}>
      <RangePicker
        style={{ width: "100%" }}
        onCalendarChange={(data) => handleChange(data)}
        defaultValue={dates}
      />
    </div>
  );
}
