import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../../../app/api';

const initialState = {
  hosts: [],
  status: 'idle',
  isFetching: false,
  appliedFilters: {
    hosts: [],
    words: null
  },
  meetings: []
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getHosts = createAsyncThunk(
  'filters/getHosts',
  async () => {
    try {
      const hosts = await API('getHosts');

      return hosts;
    } catch (error) {

    }

    return []

  }
);

export const searchMeetings = createAsyncThunk(
  'filters/searchMeetings',
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const meetings = await API('searchMeetings', state.filters.appliedFilters);
    return meetings;
  }
);

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updateFilters: (state, action) => {
      state.appliedFilters = { ...state.appliedFilters, ...action.payload };
    },

    setWordsFilter: (state, action) => {
      state.appliedFilters.words = action.payload;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getHosts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getHosts.fulfilled, (state, action) => {
        state.status = 'idle';
        state.hosts = action.payload;
      })

      .addCase(searchMeetings.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(searchMeetings.fulfilled, (state, action) => {
        state.isFetching = false;
        state.meetings = action.payload;
      })
  },
});

export const { updateFilters } = filtersSlice.actions;

export const isLoading = (state) => state.filters.status === 'loading';
export const isFetching = (state) => state.filters.isFetching;
export const hosts = (state) => state.filters.hosts.filter(u => u.enabled);
export const selectedHosts = (state) => state.filters.appliedFilters.hosts;
export const selectedWords = (state) => state.filters.appliedFilters.words;
export const selectedFilters = (state) => state.filters.appliedFilters;
export const filtersState = (state) => state;
export const meetings = (state) => state.filters.meetings;

export default filtersSlice.reducer;

export const updateAndSearch = (payload) => (dispatch, getState) => {
  dispatch(updateFilters(payload));

  dispatch(searchMeetings())
};

export const toggleHostSelection = (hostId) => (dispatch, getState) => {
  const state = getState();
  let hosts = [...state.filters.appliedFilters.hosts];

  if (hosts.includes(hostId)) {
    hosts = hosts.filter(host => host !== hostId);
  } else {
    hosts.push(hostId)
  }

  dispatch(updateFilters({ hosts }));
  dispatch(searchMeetings())
};
