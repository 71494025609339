import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  Comment,
  Tooltip,
  Typography,
} from "antd";
import {
  closeDealSection,
  selectMeeting,
} from "../../slice";
import __ from "../../../../app/i18n";
import Api from "../../../../app/api";
import moment from "moment";
import styles from "./Comment.module.css";
import { getRandomColors } from "../../../../app/color";
import { selectUser } from "../../../session/slice";
import NewCommentBox from "./NewCommentBox";
import { selectUsers } from "../../../team/slice";
import Section from "../Section";

const Comments = () => {
  const dispatch = useDispatch();
  const meeting = useSelector(selectMeeting);
  const [comments, setComments] = useState([]);
  const [colors, setColors] = useState({});
  const user = useSelector(selectUser);
  const users = useSelector(selectUsers);

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (meeting.id) {
      Api("getComments", { id: meeting.id }).then((response) => {
        setComments(response);
      });
    }
  }, [meeting]);

  useEffect(() => {
    const members = new Set([...comments.map((c) => c.userId), user._id]);
    const processedColors = getRandomColors(members.size).reduce(
      (prev, curr, index) => {
        if (!prev[[...members][index]]) {
          prev[[...members][index]] = curr;
        }

        return prev;
      },
      colors
    );

    setColors(processedColors);
  }, [comments]);

  const onClose = () => {
    dispatch(closeDealSection());
  };

  const handleSubmit = async (comment) => {
    setSubmitting(true);

    const mentionedUserIds = [];
    String(comment)
      .split(" ")
      .forEach((word) => {
        const user = users.find((user) => "@" + user.email === word);
        if (user) {
          mentionedUserIds.push(user.id);
        }
      });

    const comments = await Api("addComment", {
      id: meeting.id,
      content: comment,
      mentionedUserIds,
    });

    setComments(comments);
    setSubmitting(false);
  };

  const renderName = (user) => {
    return <>{initials(user.name, user.userId)}</>;
  };

  const initials = (name, userId) => {
    const initials = name
      .split(" ")
      .map((w) => w.charAt(0))
      .join("");

    return (
      <span
        className={styles.initialsBuble}
        style={{ backgroundColor: colors[userId] }}
      >
        {initials}
      </span>
    );
  };

  const checkCommentMention = (comment) => {
    if (users.length) {
      const parts = String(comment).split(" ");

      return parts.map((p) => {
        const u = users.find((user) => "@" + user.email === p);

        if (u) {
          return (
            <>
              <Typography.Text
                mark={user && "@" + user.email === p ? true : false}
              >
                <span style={{ fontWeight: 600, cursor: "pointer" }}>
                  @{user.name + " " + user.lastName}
                </span>
              </Typography.Text>{" "}
            </>
          );
        } else {
          return (
            <>
              <span>{p}</span>{" "}
            </>
          );
        }
      });
    }
  };

  return (
    <Section
      title={__("meetings.details.sections.comments")}
      sectionKey={"comments"}
      position={'team'}
    >
      <Comment
        avatar={renderName({
          name: user.name + " " + user.lastName,
          userId: user._id,
        })}
        content={
          <NewCommentBox
            avatar={renderName({
              name: user.name + " " + user.lastName,
              userId: user._id,
            })}
            onSubmit={(e) => handleSubmit(e)}
            submitting={submitting}
          />
        }
      />

      {(comments || [])
        .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
        .map((comment) => (
          <Comment
            key={`comment:${comment.id}`}
            dataId={`comment:${comment.id}`}
            author={<a>{comment.who}</a>}
            avatar={renderName({ name: comment.who, userId: comment.userId })}
            content={<p>{checkCommentMention(comment.content)}</p>}
            datetime={
              <Tooltip title={comment.createdAt}>
                <span>{moment(comment.createdAt).fromNow()}</span>
              </Tooltip>
            }
          />
        ))}
    </Section>
  );
};

export default Comments;
