import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../app/api";
import __ from "../../app/i18n";
import {
  showGlobalErrors,
  showGlobalSuccess,
} from "../loading/slice";
import { loadAppFilters } from "../meetings/filters/slice";

const initialState = {
  status: "loading",
  apps: [],
  fetched: false,
  fetchedApp: false,
  app: null,
};

export const loadApps = createAsyncThunk(
  "marketplace/getApps",
  async (payload, { dispatch }) => {
    const response = await Api("getApps");
    
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    return response;
  }
);

export const getAppForm = createAsyncThunk(
  "marketplace/getAppForm",
  async (payload, { dispatch }) => {
    const response = await Api("getAppForm", payload);
    
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    return response;
  }
);
export const saveAppSettings = createAsyncThunk(
  "marketplace/saveAppSettings",
  async (payload, { dispatch }) => {
    const response = await Api("saveAppSettings", payload);
    dispatch(loadAppFilters());
    
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    } else {
      dispatch(showGlobalSuccess([__('apps.generic.alerts.updatedSucessFull')]));
    }

    return response;
  }
);

export const loadMeetingLibraries = createAsyncThunk(
  "library/loadMeetingLibraries",
  async (payload, { dispatch }) => {
    const response = await Api("getLibrariesByMeeting", payload);
    
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    return response;
  }
);

export const loadMeetingsByLibrary = createAsyncThunk(
  "library/getMeetingsByLibrary",
  async (payload, { dispatch }) => {

    const response = await Api("getMeetingsByLibrary", payload);
    
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    return response;
  }
);

export const marketplaceSlice = createSlice({
  name: "marketplace",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.status = "loading";
    },
    stopLoading: (state) => {
      state.status = "iddle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadApps.pending, (state) => {
        state.status = "loading";
      })
      
      .addCase(loadApps.fulfilled, (state, action) => {
        state.status = 'idle';
        state.apps = action.payload;
        state.fetched = true;
      })
      .addCase(getAppForm.pending, (state) => {
        state.status = "loading";
        state.fetchedApp = false;
      })
      
      .addCase(getAppForm.fulfilled, (state, action) => {
        state.status = 'idle';
        state.app = action.payload;
        state.fetchedApp = true;
      })
      .addCase(saveAppSettings.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(saveAppSettings.fulfilled, (state, action) => {
        state.status = 'idle';
        state.app = action.payload;
        state.fetchedApp = true;
      })
  },
});

export const { clearFetched, startLoading, stopLoading, markAsSaved, markAsNotSaved } = marketplaceSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectApps = (state) => state.marketplace.apps;
export const selectIsFetching = (state) => state.marketplace.status === 'loading';
export const selectFetchedApps = (state) => state.marketplace.fetched;
export const selectFetchedApp = (state) => state.marketplace.fetchedApp;
export const selectApp = (state) => state.marketplace.app;
export const selectCurrentApp = (state) => state.marketplace.app;

export default marketplaceSlice.reducer;
