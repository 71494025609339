import moment from "moment";
import __ from "./i18n";

export function getSecondsDiff(startDate, endDate) {
  let msInSecond = 1000;

  return Math.round(Math.abs(endDate - startDate) / msInSecond);
}

export function removeAccents(text) {
  const sustitutions = {
    àáâãäå: "a",
    ÀÁÂÃÄÅ: "A",
    èéêë: "e",
    ÈÉÊË: "E",
    ìíîï: "i",
    ÌÍÎÏ: "I",
    òóôõö: "o",
    ÒÓÔÕÖ: "O",
    ùúûü: "u",
    ÙÚÛÜ: "U",
    ýÿ: "y",
    ÝŸ: "Y",
    ß: "ss",
    ñ: "n",
    Ñ: "N",
  };
  // Devuelve un valor si 'letter' esta incluido en la clave
  function getLetterReplacement(letter, replacements) {
    const findKey = Object.keys(replacements).reduce(
      (origin, item, index) => (item.includes(letter) ? item : origin),
      false
    );
    return findKey !== false ? replacements[findKey] : letter;
  }
  // Recorre letra por letra en busca de una sustitución
  return text
    .split("")
    .map((letter) => getLetterReplacement(letter, sustitutions))
    .join("");
}

export const dateToVideoTime = (message, meeting) => {
  const timestamp =
    moment(message.startAt).diff(meeting.videoStartAt, "second") - 5;

  return timestamp;
};

export function convertTime(seconds) {
  var hours = Math.floor(seconds / 3600);
  var minutes = Math.floor((seconds - hours * 3600) / 60);

  if (!!hours) {
    if (!!minutes) {
      return `${hours}h ${minutes}m ${seconds}s`;
    } else {
      return `${hours}h ${seconds}s`;
    }
  }
  if (!!minutes) {
    return `${minutes}m ${seconds}s`;
  }
  return `${seconds}s`;
}

export function getTimestampOfMessageToPlay (meeting, item) {
  if (['GOOGLE', 'MICROSOFT'].includes(meeting.provider)) {
    const diff = moment(item.startAt).diff(meeting.videoStartAt, 'second') - 1;

    return Math.abs(diff);
  }

  return item.startAt;
}

export const appName = 'Samu.ai';

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  
  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!');
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
  });
}

export function secondsToHHMMSS(totalSeconds) {
  var hours = Math.floor(totalSeconds / 3600);
  var minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
  var seconds = Math.floor(totalSeconds - (hours * 3600) - (minutes * 60));

  // Padding the values to ensure they are two digits
  if (hours < 10) { hours = "0" + hours; }
  if (minutes < 10) { minutes = "0" + minutes; }
  if (seconds < 10) { seconds = "0" + seconds; }

  return hours + ':' + minutes + ':' + seconds;
}

export function fixGPTEncoding (str) {
  var textarea = document.createElement("textarea");
  textarea.innerHTML = str;
  return textarea.value;
}


export const appMeetingLangsKeys = ['BRAZIL', 'UK', 'MEXICO', 'HEBREW', 'ITALIAN', 'FRENCH'];
export const appMeetingLangs = appMeetingLangsKeys.map(key => ({ 
  value: key,
  label: __(`lang.${String(key).toLowerCase()}.label`),
  flag: __(`lang.${String(key).toLowerCase()}.flag`),
  small: __(`lang.${String(key).toLowerCase()}.small`),
}));