import { Form, Input } from "antd";
import _ from "lodash";
import { useState } from "react";
import AppItem from "../AppItem";

function App(props) {
  const [url, setUrl] = useState(props.app.url);
  const [enabled, setEnabled] = useState(
    props.app.enabled
  );


  const handleSave = () => {
    const newSettings = {
      ...props.app,
      enabled,
      url
    };

    props.handleOnSave(newSettings);
  };

  return (
    <AppItem app={props.app} onToggleStatus={e => setEnabled(e)} canSave={props.app} onSave={handleSave} canToggleStatus={props.app} enabled={enabled}>
      <Form>
        <Input onChange={e => setUrl(e.target.value)} value={url}/>
      </Form>
    </AppItem>
  );
}

export default App;
