import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "./Header";
import { loadConversation, selectIsLoading } from "./slice";
import { useDispatch, useSelector } from "react-redux";
import Chat from "./Chat";
import Sidebar from "./Sidebar";
import { Col, Row } from "antd";
import Loading from "../loading/Index";

export default function Detail(props) {
  const dispatch = useDispatch();
  const { id } = useParams("id");
  const loading = useSelector(selectIsLoading);

  useEffect(() => {
    // Fetch conversation
    const fetch = async () => {
      dispatch(loadConversation(id));
    };

    fetch();
  }, [id]);

  if (loading) {
    return <Loading/>
  }

  return (
    <div>
      <Header />
      <Row>
        <Col md={7}>
          <Sidebar />
        </Col>

        <Col md={17} style={{ maxHeight: "82vh", overflowY: "scroll", padding: "15px" }}>
          <Chat />
        </Col>
      </Row>
    </div>
  );
}
