import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../../../app/api";
import { showGlobalErrors } from "../../../loading/slice";
import { updateCallTypeSelecion } from "../slice";

const initialState = {
  status: "loading",
  callTypes: [],
  fetched: false,
  isNewModalOpen: false,
};

export const updateCallType = createAsyncThunk(
  "callType/update",
  async (payload, { dispatch }) => {
    dispatch(startLoading());

    const response = await Api("updateCallType", payload);
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    dispatch(stopLoading());
    dispatch(loadCallTypes())

    dispatch(updateCallTypeSelecion(response));


    return response;
  }
);

export const createCallType = createAsyncThunk(
  "callType/create",
  async (payload, { dispatch }) => {
    dispatch(startLoading());

    const response = await Api("createCallType", payload);
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    dispatch(stopLoading());
    dispatch(loadCallTypes())

    dispatch(updateCallTypeSelecion(response));


    return response;
  }
);

export const loadCallTypes = createAsyncThunk(
  "callType/loadCallTypes",
  async (payload, { dispatch }) => {
    const response = await Api("getCallTypes");
    
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    return response;
  }
);

export const callTypeSlice = createSlice({
  name: "callType",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.status = "loading";
    },
    stopLoading: (state) => {
      state.status = "iddle";
    },
    clearFetched: (state) => {
      state.fetched = false;
      state.callTypes = [];
    },
    openNewModal: (state) => {
      state.isNewModalOpen = true;
    },
    closeNewModal: (state) => {
      state.isNewModalOpen = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCallType.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createCallType.fulfilled, (state, action) => {
        state.status = 'idle';
        state.callTypes = [...state.callTypes, action.payload];
      })

      .addCase(loadCallTypes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadCallTypes.fulfilled, (state, action) => {
        state.status = 'idle';
        state.callTypes = action.payload;
        state.fetched = true;
      })
      
  },
});

export const { clearFetched, startLoading, stopLoading, openNewModal, closeNewModal } = callTypeSlice.actions;

export const selectCallTypes = (state) => state.callType.callTypes;
export const selectIsFetching = (state) => state.callType.status === 'loading';
export const selectIsNewCallTypeModalOpen = (state) => !!state.callType.isNewModalOpen;
export const selectCallTypesFetched = (state) => state.callType.fetched;

export default callTypeSlice.reducer;
