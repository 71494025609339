import {
  Button,
  Card,
  Layout,
  Table,
  Modal,
  Dropdown,
  Badge,
  Menu,
  Tooltip,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import GoogleCalendarLogo from "../profile/tabs/assets/googleCalendarLogo.png";

import { isFetching } from "./filters/slice";
import {
  DeleteOutlined,
  WarningOutlined,
  ReloadOutlined,
  DownOutlined,
  LoadingOutlined,
  EditOutlined
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import {
  deleteUser,
  loadUsers,
  resetPassword,
  selectUsers,
  toggleUserAccess,
} from "./slice";
import NewTeamUser from "./NewTeamUser";
import EditTeamUser from "./EditTeamUser";
import styles from "./team.module.css";
import ManagerComponent from "../session/ManagerComponent";
import __ from "../../app/i18n";
import Api from "../../app/api";
import { Language, AlertTriangle } from "tabler-icons-react";
import { appMeetingLangs } from "../../app/utils";
const { Content } = Layout;

const SECTIONS = {
  NEW_USER: "newUser",
  EDIT_USER: "editUser",
};

const loadingCards = (
  <>
    <Card loading={true} style={{ marginBottom: "15px" }} />
    <Card loading={true} style={{ marginBottom: "15px" }} />
    <Card loading={true} style={{ marginBottom: "15px" }} />
    <Card loading={true} style={{ marginBottom: "15px" }} />
    <Card loading={true} style={{ marginBottom: "15px" }} />
    <Card loading={true} style={{ marginBottom: "15px" }} />
    <Card loading={true} style={{ marginBottom: "15px" }} />
  </>
);

export default function TeamList() {
  const selectIsFetching = useSelector(isFetching);
  const users = useSelector(selectUsers);
  const [section, setSection] = useState(false);
  const [userForDelete, setUserForDelete] = useState(false);
  const [userForEdit, setUserForEdit] = useState(false);
  const [googleConnections, setGoogleConnections] = useState(null);
  const [usersWithoutScope, setUsersWithoutScope] = useState(null);
  const dispatch = useDispatch();
  document.title = `${__("appName")} - ${__("team.label")}`;

  useEffect(() => {
    dispatch(loadUsers());
    Api('googleConnections', {}).then(data => {
      setGoogleConnections(data.usersWithGoogle);
      setUsersWithoutScope(data.usersWithGoogleWithoutScope);
    });
  }, []);

  const handleNewUser = () => {
    setSection(SECTIONS.NEW_USER);
  };

  const handleEditUser = (user) => {
    setUserForEdit(user);
    setSection(SECTIONS.EDIT_USER);
  };

  const showUserDeleteModal = (id) => {
    setUserForDelete(id);
  };

  const handleToggleUserAccess = (userId) => {
    dispatch(toggleUserAccess({ id: userId }, { id: userId }));
  };

  const handleDeleteUser = () => {
    dispatch(deleteUser({ id: userForDelete }, {}));
    setUserForDelete(null);
  };

  const handleResetPassword = (userId) => {
    dispatch(resetPassword({ userId }));
  };

  const isGoogleConnected = (user) => {
    if (!googleConnections) {
      return <LoadingOutlined style={{ fontSize: 15 }} spin />
    }

    if (Array.isArray(googleConnections) && googleConnections.includes(user.id)) {
      return <Badge status="success" style={{ paddingLeft: "25px" }} />
    } else if (Array.isArray(usersWithoutScope) && usersWithoutScope.includes(user.id)) {
      return <Tooltip title={__("generic.buttons.needPermission")}>
        <div style={{ paddingLeft: "16px" }}>
          <AlertTriangle color="#faad15"/>
        </div>
      </Tooltip>
    }
  };

  const actions = (user) => {
    const menu = (
      <Menu>
        <Menu.Item
          icon={
            <EditOutlined style={{ fontSize: "20px" }} />
          }
          onClick={() => handleEditUser(user)}
        >
          {__("team.edit")}
        </Menu.Item>
        <Menu.Item
          hidden={!user.enabled}
          icon={
            <WarningOutlined style={{ fontSize: "20px", color: "#ffa800" }} />
          }
          onClick={() => handleToggleUserAccess(user.id)}
        >
          {__("team.deactivate")}
        </Menu.Item>

        <Menu.Item
          icon={
            <DeleteOutlined
              style={{
                fontSize: "20px",
                color: "red",
              }}
            />
          }
          onClick={() => showUserDeleteModal(user.id)}
        >
          {__("generic.fields.delete")}
        </Menu.Item>
        <Menu.Item
          icon={
            <ReloadOutlined
              style={{
                fontSize: "20px",
                color: "green",
              }}
            />
          }
          onClick={() => handleResetPassword(user.id)}
        >
          {__("generic.fields.resetPassword")}
        </Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={menu}>
        <a>
          Acciones <DownOutlined />
        </a>
      </Dropdown>
    );
  };
  const columns = [
    {
      title: __("team.list.name"),
      dataIndex: "name",
      key: "name",
      render: (name, user) => renderName(user),
      sorter: (a, b) => a.name < b.name ?  -1 : 1,
    },
    {
      title: __("team.list.email"),
      dataIndex: "email",
      key: "email",
      render: (name, user) => user.email,
      sorter: (a, b) => a.email < b.email ?  -1 : 1,
    },
    {
      title: __("team.list.role"),
      dataIndex: "role",
      key: "role",
      render: (name, user) =>
        __("roles." + String(user.roleName).toLowerCase()),
      sorter: (a, b) => a.roleName < b.roleName ?  -1 : 1,
    },
    {
      title: __("team.list.status"),
      dataIndex: "status",
      key: "status",
      render: (name, user) =>
        user.enabled ? (
          <>
            <Badge status="success" /> {__("team.list.active")}
          </>
        ) : (
          <>
            <Badge status="error" /> {__("team.list.deactive")}
          </>
        ),
      sorter: (a, b) => a.status < b.status ?  -1 : 1,
    },
    {
      title: <><img width="20px" src={GoogleCalendarLogo} title="Conectado a Google" alt="Conectado a Google"/></>,
      dataIndex: "recording",
      key: "recording",
      render: (_, user) => isGoogleConnected(user),
    },
    {
      title: <Language style={{ height: "20px", "width": "20px", marginTop: "5px" }}/>,
      dataIndex: "lang",
      key: "lang",
      render: (_, user) => <>{appMeetingLangs.find(l => l.value === user.lang).flag}</>,
    },
    {
      dataIndex: "actions",
      key: "actions",
      render: (_, user) => actions(user),
    },
  ];

  const renderName = (user) => {
    return (
      <>
        {initials(user)}
        {user.name}
      </>
    );
  };

  const renderUserDetails = (user) => {
    return (
      <p
        key={`expandable#${user.id}`}
        style={{
          margin: 0,
        }}
      >
        {user.id}
        {JSON.stringify(user, null, 2)}
      </p>
    );
  };

  const initials = (user) => {
    if (user.image) {
      return (
        <img
          className={styles.initialsBuble}
          src={user.image}
          alt={user.name}
        />
      );
    }

    const initials = user.name
      .split(" ")
      .map((w) => w.charAt(0))
      .join("");

    return <span className={styles.initialsBuble}>{initials}</span>;
  };

  return (
    <>
      <Content style={{ padding: "0 25px" }}>
        <Layout
          className={"site-layout-background main-layout-container"}
          style={{ padding: "24px 0" }}
        >
          <Content style={{ padding: "0 24px", minHeight: 280 }}>
            <Modal
              title={__("generic.fields.needConfirmTitle")}
              open={!!userForDelete}
              onOk={() => handleDeleteUser()}
              onCancel={() => setUserForDelete(null)}
              okText={__("generic.fields.confirm")}
              cancelText={__("generic.fields.cancel")}
            >
              <p>{__("generic.fields.needConfirmContent")}</p>
            </Modal>
            <ManagerComponent>
              <Button
                onClick={handleNewUser}
                type="primary"
                className={styles.addTeamCircle}
              >
                +
              </Button>
            </ManagerComponent>
            {selectIsFetching ? (
              loadingCards
            ) : (
              <Table
                key={"team:table"}
                columns={columns}
                dataSource={(users || []).map((u) => ({ ...u, key: u.id }))}
              />
            )}
            <ManagerComponent>
              <NewTeamUser
                open={section === SECTIONS.NEW_USER}
                onClose={() => setSection("")}
              />
              <EditTeamUser
                open={section === SECTIONS.EDIT_USER && userForEdit}
                onClose={() => { setSection(); setUserForEdit() }}
                user={userForEdit}
              />
            </ManagerComponent>
          </Content>
        </Layout>
      </Content>
    </>
  );
}
