import { useSelector } from "react-redux";
import { selectHasManagerAccess } from "./slice";

export default function ManagerComponent ({ children, notManagerComponent }) {
  const hasManagerAccess = useSelector(selectHasManagerAccess);

  if (hasManagerAccess) {
    return children;
  } else if (notManagerComponent) {
    return notManagerComponent;
  }
}