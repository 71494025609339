import AnalyticBar from "./AnalyticBar";
import { Typography } from "antd";
import AnalyticsTabContent from "./AnalyticsTabContent";
import PeriodGraphs from "./PeriodGraphs";

export default function CallDurationTab({ analyticsState }) {
  return (
    <AnalyticsTabContent>
      <div>
        <div>
          <Typography.Title
            className={"analyticsTabTitle"}
            level={3}
            style={{ marginBottom: "15px" }}
          >
            Duracion de las llamadas
          </Typography.Title>
          <AnalyticBar
            className={"dtabContent2Items"}
            userAnalytics={analyticsState.callDuration.durations}
            itemKeyName={"Total del usuario"}
            prop={'avg'}
            avg={analyticsState.callDuration.avg}
            useTimeFormatter={true}
          />
        </div>
        <PeriodGraphs title={'Evolución de la duración de las llamadas'} userAnalytics={analyticsState.callDuration.periods}/>
      </div>
    </AnalyticsTabContent>
  );
}
