import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAnalytics, selectIsComparing } from "./slice";
import TopTabs from "./TopTabs";
import { loadUsers } from "../team/slice";
import { Content } from "antd/lib/layout/layout";
import { Layout } from "antd";
import { getConfig } from "../session/slice";

export default function AnalyticsPage() {
  const dispatch = useDispatch();
  const isComparing = useSelector(selectIsComparing);

  useEffect(() => {
    dispatch(getAnalytics());
    dispatch(loadUsers());
    dispatch(getConfig());
  }, []);

  return <Content style={{ padding: "0" }}>
    <Layout
      className={"site-layout-background main-layout-container"}
      style={{ padding: "24px 0" }}
    >
      <Content style={{ padding: "0", minHeight: 280 }}>
        <TopTabs />
        {isComparing ? <TopTabs isSecondary={true} /> : ""}
      </Content>
    </Layout>
  </Content>;
}
