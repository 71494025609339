import AnalyticBar from "./AnalyticBar";
import AnalyticsTabContent from "./AnalyticsTabContent";
import { Typography } from "antd";
import PeriodGraphs from "./PeriodGraphs";

export default function TalkRatioTab({ analyticsState }) {

  return (
    <AnalyticsTabContent>
      <Typography.Title
        className={"analyticsTabTitle"}
        level={3}
        style={{ marginBottom: "15px" }}
      >
        Talk Ratio
      </Typography.Title>
      <AnalyticBar
        avg={analyticsState.talkRatio.avg}
        unit="%"
        userAnalytics={analyticsState.talkRatio.talkRatios}
        prop="avg"
        itemKeyName={"Promedio del usuario"}
      />
      <PeriodGraphs userAnalytics={analyticsState.talkRatio.periods} title={'Evolución del talk ratio'}/>
    </AnalyticsTabContent>
  );
}
