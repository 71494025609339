import { Avatar, Comment, Tooltip, Typography } from "antd";
import moment from "moment";
import __ from "../../../app/i18n";
import { useNavigate } from "react-router-dom";
import styles from "../notification.module.css";

export default function NewMeeting({ notification, onClose }) {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        navigate(`/meeting/${notification.meeting._id}`);
        onClose();
      }}
    >
      <Comment
        key={`comment:${notification.id}`}
        avatar={<Avatar size={75} src={notification.payload.thumb} className={styles.thumb}/>}
        author={
          <Typography.Title
            level={5}
            style={{
              margin: 0,
            }}
          >
            {notification.meeting.name +
              (notification.payload.client
                ? ` :: ${notification.payload.client}`
                : "")}
          </Typography.Title>
        }
        content={<>{__("notifications.newMeeting")}</>}
        datetime={
          <Tooltip title={notification.createdAt}>
            <span style={{ color: "#000", display: "block", width: "100% !important" }}>
              {moment(notification.createdAt).fromNow()}
            </span>
          </Tooltip>
        }
      />
    </div>
  );
}
