import { Form, Input, Modal } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import __ from "../../app/i18n";
import { createTag } from "./slice";

const NewTagModal = ({ open, onClose }) => {
  const [tagName, setTagName] = useState("");
  const dispatch = useDispatch();

  const handleCreation = () => {
    if (tagName) {
      dispatch(createTag({ name: tagName }))
      onClose()
    }
  };

  return (
    <Modal
      title={__("tag.new")}
      okText={__("tag.create")}
      centered
      open={open}
      onOk={() => handleCreation()}
      onCancel={onClose}
    >
      <Form.Item
        label={__("tag.name")}
        required
        tooltip={__("generic.required.library")}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input required value={tagName} onChange={(e) => setTagName(e.target.value)} placeholder={__("tag.newPlaceHolder")} />
      </Form.Item>
    </Modal>
  );
};

export default NewTagModal;
