import { Result } from "antd";

export default function InvalidShare() {
  return (
    <div style={{ position: "relative" }}>
      <Result
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, 50%)",
        }}
        title="Acceso expirado"
        subTitle="Contactate con el usuario para obtener un nuevo link y disfrutar de la grabación."
      />
    </div>
  );
}
