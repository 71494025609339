import { useDispatch, useSelector } from "react-redux";
import { Drawer, Divider } from "antd";
import __ from "../../../../app/i18n";
import QuestionList from "./Questions";
import TrackerMeeting from "./TrackerMeeting";
import SpokenTimePercentageMetric from "./SpokenTimePercentageMetric";
import SpokenTimesMetric from "./SpokenTimesMetric";
import Section from "../Section";

const Metrics = () => {
  return (
    <Section sectionKey="metrics" title={__("meetings.details.sections.metrics")}>
      <SpokenTimePercentageMetric />
      <Divider />
      <SpokenTimesMetric />

      <Divider />
      <QuestionList />
      <Divider />

      <TrackerMeeting />
      <Divider />
    </Section>
  );
};

export default Metrics;
